











import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";
import { Debug, Variant, EmptyVariant } from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";
import VariantView from "./VariantView.vue";

@Component({ components: { VariantView, BModalWrapper } })
export default class ProductVariantEditor extends Vue {
  @Prop() debug!: Debug;
  @Prop() value!: Variant[];
  @Ref() modal!: BModalWrapper;
  private variants: Variant[] = [];
  private status = "";

  public async show() {
    this.variants = this.value || [];
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }
  private save() {
    this.$emit("input", this.variants);
    this.hide();
  }

  private add() {
    this.variants.push(EmptyVariant());
  }

  private remove(index: number) {
    this.variants.splice(index, 1);
  }

  private cancel() {
    this.hide();
  }
}
