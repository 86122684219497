


















import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { StandaloneNavSetting, Debug, ProgressBarModes, NavState } from "@monowarestudios/js-util";
import StandaloneNavEditor from "@/components/product_manager_views/StandaloneNavEditor.vue";

@Component({ components: { StandaloneNavEditor } })
export default class StandaloneNavSettingEditor extends Vue {
  @Prop() value!: StandaloneNavSetting[];
  @Prop() propClientID!: number;
  @Prop() navs!: string[];
  @Prop() debug!: Debug;
  private standaloneNavs: StandaloneNavSetting[] = [];
  private selected = 0;
  private standaloneToAddName = "";
  private standaloneToAdd: StandaloneNavSetting = {
    internalName: "",
    nav: "",
    progressBarMode: ProgressBarModes.FRACTION,
    includePriceBreakdown: false,
    showCurrentPosition: false
  };


  private addStandaloneNav() {
    this.standaloneNavs.push(
      JSON.parse(JSON.stringify(this.standaloneToAdd))
    );
    this.selected = this.standaloneNavs.length - 1;
    this.standaloneToAdd = {
      internalName: "",
      nav: "",
      progressBarMode: ProgressBarModes.FRACTION,
      includePriceBreakdown: false,
      showCurrentPosition: false
    };
  }

  private deleteStandaloneNav(index: number) {
    this.standaloneNavs.splice(index, 1);
    this.selected = this.standaloneNavs.length - 1;
    this.$forceUpdate();
  }
  @Watch("value")
  private onValue() {
    this.standaloneNavs = JSON.parse(JSON.stringify(this.value));
    this.selected = this.standaloneNavs.length - 1;
  }
  private mounted() {
    this.onValue();
  }
  private save() {
    this.$emit("input", this.standaloneNavs);
  }

  private selectStandaloneNav(index: number) {
    this.selected = index;
  }
  private copyStandaloneNav(source: StandaloneNavSetting) {
    this.standaloneNavs.push(JSON.parse(JSON.stringify(source)));
    this.selected = this.standaloneNavs.length - 1;
  }
}
