


























import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { currencyList, StandaloneCurrencySelectorSetting, Currency, Debug } from "@monowarestudios/js-util";
@Component
export default class StandaloneCurrencySelectorEditor extends Vue {
  @Prop() value!: StandaloneCurrencySelectorSetting[];
  @Prop() propClientID!: number;
  @Prop() debug!: Debug;
  @Prop() availableStandaloneCurrencySelectors!: StandaloneCurrencySelectorSetting[];
  private standaloneCurrencySelectors: StandaloneCurrencySelectorSetting[] = [];
  private selected = 0;
  private codes = currencyList.map((c:Currency) => ({text: c.currency, value: c.code}))
  private addStandaloneCurrencySelector() {
      this.standaloneCurrencySelectors.push({
        currencies: [],
        internalName: "",
        displayName: "",
      }
      );
      this.selected = this.standaloneCurrencySelectors.length - 1;
  }

  private deleteStandaloneCurrencySelector(index: number) {
    this.standaloneCurrencySelectors.splice(index, 1);
    this.selected = this.standaloneCurrencySelectors.length - 1;
    this.$forceUpdate();
  }
  @Watch("value")
  private onValue() {
    this.standaloneCurrencySelectors = JSON.parse(JSON.stringify(this.value));
    this.selected = this.standaloneCurrencySelectors.length - 1;
  }
  private mounted() {
    this.onValue();
  }
  private async save() {
    this.$emit("input", this.standaloneCurrencySelectors);
  }

  private selectStandaloneCurrencySelector(index: number) {
    this.selected = index;
  }
  private copyStandaloneCurrencySelector(source: StandaloneCurrencySelectorSetting) {
    this.standaloneCurrencySelectors.push(JSON.parse(JSON.stringify(source)));
    this.selected = this.standaloneCurrencySelectors.length - 1;
  }
  private addCurrency() {
    this.standaloneCurrencySelectors[this.selected].currencies.push("")
  }
  private removeCurrency(i:number) {
    this.standaloneCurrencySelectors[this.selected].currencies.splice(i,1)
  }
}
