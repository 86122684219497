import { render, staticRenderFns } from "./EditClientView.vue?vue&type=template&id=12677650&scoped=true&lang=pug&"
import script from "./EditClientView.vue?vue&type=script&lang=ts&"
export * from "./EditClientView.vue?vue&type=script&lang=ts&"
import style0 from "./EditClientView.vue?vue&type=style&index=0&id=12677650&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12677650",
  null
  
)

export default component.exports