



















































































import { currencyList, type Currency,  type StandaloneFinanceSetting, TimeType } from '@monowarestudios/js-util';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class StandaloneFinanceEditor extends Vue {
  @Prop() value!: StandaloneFinanceSetting;
  selected: StandaloneFinanceSetting = {
    internalName: '',
    includeTitle: true,
    title: '',
    includePriceInTitle: true,
    includeProductInTitle: true,
    estimatedPaymentsOptions: {
      endNumber: 0,
      incrementAmount: 0,
      startNumber: 0,
      type: TimeType.DAY
    },
    extendedWarrantyPrice: 0,
    extendedWarrantyPrices: [],
    frequencyOptions: [],
    includeDownPayment: true,
    includeDownPaymentAmountDuplicate: true,
    includeEstimatedPayments: true,
    includeExtendedWarranty: true,
    includeFrequency: true,
    includeRate: true,
    includeReview: true,
    includeSummary: true,
    miscTexts: [],
    startingDownPayment: 0,
    startingRate: 0
  }

  addFrequencyOption() {
    this.selected.frequencyOptions.push({
      frequencyAmount: 1,
      name: '',
      type: TimeType.DAY
    })
  }

  addMiscTexts() {
    this.selected.miscTexts.push({
      internalName: "",
      text: ""
    })
  }

  get timeTypes() {
    return Object.entries(TimeType).map(([_key, val]) => val)
  }

  @Watch("value")
  private onChange() {
    this.selected = JSON.parse(JSON.stringify(this.value))
  }

  private mounted() {
    this.onChange()
  }

  save() {
    const epo = this.selected.estimatedPaymentsOptions
    this.selected.estimatedPaymentsOptions = {
      endNumber: Number.parseFloat(epo.endNumber + ""),
      incrementAmount: Number.parseFloat(epo.incrementAmount + ""),
      startNumber: Number.parseFloat(epo.startNumber + ""),
      type: epo.type
    }
    this.selected.frequencyOptions = this.selected.frequencyOptions.map((fo) => {
      return {
        frequencyAmount: Number.parseFloat(fo.frequencyAmount + ""),
        name: fo.name,
        type: fo.type
      }
    })
    this.selected.extendedWarrantyPrices = this.selected.extendedWarrantyPrices.map((c: Currency) => ({ ...c, price: parseInt(c.price as any || "0") }));

    this.selected.startingDownPayment = Number.parseFloat(this.selected.startingDownPayment + "")
    this.selected.extendedWarrantyPrice = Number.parseFloat(this.selected.extendedWarrantyPrice + "")
    this.selected.startingRate = Number.parseFloat(this.selected.startingRate + "")
    this.$emit("input", this.selected)
  }

  private addPrice() {
    this.selected.extendedWarrantyPrices.push({ code: "", currency: "", price: 0 });
  }
  private removePrice(index: number) {
    this.selected.extendedWarrantyPrices.splice(index, 1);
  }
  private codes = currencyList.sort((a: Currency, b: Currency) => a.currency >= b.currency ? 1 : -1).map((c: Currency) => ({ text: c.currency, value: c.code }));

}
