




















import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import {
  Debug,
  CartSettings,
} from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";

@Component({ components: { BModalWrapper } })
export default class OptionSettings extends Vue {
  @Prop() debug!: Debug;
  @Prop() value!: CartSettings;
  @Ref() modal!: BModalWrapper;
  private options = ["cart", "text", "image", "quantity", "checkbox", "textArea"];
  private selectedOptionDataOptions: CartSettings = new CartSettings();
  private optionDataOptions: { description: string; key: string }[] = [
    {
      key: "enforceDefault",
      description:
        "Enforce defaults? (enable if this button should enforce its' default even if the customer has already selected something else",
    },
    {
      key: "isClear",
      description:
        "True if this option removes the category from cart/line items",
    },
  ];

  public show() {
    this.selectedOptionDataOptions = this.value;
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  public save() {
    this.$emit("input", {...this.selectedOptionDataOptions, textCharacterLimit:parseInt(this.selectedOptionDataOptions.textCharacterLimit as any)});
    this.hide();
  }
}
