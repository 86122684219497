











import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { Requests, Network, PresignedUrlInfo } from "@monowarestudios/js-util";

@Component
export default class ImageUpload extends Vue {
  @Prop() value!: number;
  @Prop() clientID!: number;
  private file: File | null = null;
  private status = "";
  private preview = "";

  @Watch("file")
  private async onFileUploaded() {
    if (this.file) {
      // prepare our preview
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.preview = e.target.result;
      };
      reader.readAsDataURL(this.file! as Blob);
    }
  }

  private async onUploadClick() {
    let [data, success] = await Requests.getInternalPresignedURL(
      this.file!.type,
      this.file!.size,
      this.clientID
    );
    if (success) {
      const uploadRes = await fetch((data as PresignedUrlInfo).uploadUrl, {
        method: "PUT",
        body: this.file,
        headers: {
          "content-type": this.file!.type,
          "content-length": this.file!.size + "",
        },
      });
      if (uploadRes.status == 200) {
        this.$emit("input", data);
      } else {
        throw await uploadRes.json();
      }
    } else {
      throw data;
    }
  }
}
