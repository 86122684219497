















































































































import { Component, Prop, Vue, Ref, Watch } from "vue-property-decorator";
import {
  Debug,
  RenderSettings,
  RenderTypes,
  CSS,
Variant,
} from "@monowarestudios/js-util";
import { parseCSS, encodeCSS } from "../../../css";
import BModalWrapper from "@/components/BModalWrapper.vue";
import SelectDeployable from "@/components/SelectDeployable.vue";
type mergedTypes = {
  renderSettings: RenderSettings;
  portraitRenderSettings: RenderSettings;
};
@Component({ components: { BModalWrapper, SelectDeployable } })
export default class RenderSettingsView extends Vue {
  @Prop() debug!: Debug;
  @Prop() value!: mergedTypes | RenderSettings;
  @Prop() type!: string;
  @Prop() clientID!: string;
  @Prop() variants!: Variant[];
  @Ref() modal!: BModalWrapper;
  private renderSettings = new RenderSettings();
  private portraitRenderSettings = new RenderSettings();
  private cssString = "";
  private portraitCssString = "";

  public show() {
    if (this.type == "category") {
      this.renderSettings = JSON.parse(
        JSON.stringify((this.value as mergedTypes).renderSettings)
      );
      this.cssString = encodeCSS(this.renderSettings.css);
      this.portraitRenderSettings = JSON.parse(
        JSON.stringify((this.value as mergedTypes).portraitRenderSettings)
      );
      this.portraitCssString = encodeCSS(this.portraitRenderSettings.css);
    } else {
      this.renderSettings = JSON.parse(JSON.stringify(this.value));
      this.cssString = encodeCSS(this.renderSettings.css);
    }
    this.modal.show();
  }

  @Watch("renderSettings.renderType")
  private updateSelected(newVal: string) {
    this.cssString = encodeCSS(
      newVal == this.renderSettings.renderType
        ? { ...this.renderOptions[newVal], ...this.renderSettings.css }
        : this.renderOptions[newVal]
    );
  }
  @Watch("portraitRenderSettings.renderType")
  private updateSelectedPortrait(newVal: string) {
    this.portraitCssString = encodeCSS(
      newVal == this.portraitRenderSettings.renderType
        ? { ...this.renderOptions[newVal], ...this.portraitRenderSettings.css }
        : this.renderOptions[newVal]
    );
  }

  private get renderOptions() {
    return RenderTypes[this.type].reduce(
      (acc, val: RenderSettings) => ({
        ...acc,
        [val.renderType]: val.css,
      }),
      {}
    ) as { [index: string]: CSS };
  }

  private get renderTypes() {
    return RenderTypes[this.type].map((r: RenderSettings) => r.renderType);
  }

  private getCss() {
    return parseCSS(this.cssString.trim());
  }
  private getPortraitCss() {
    return parseCSS(this.portraitCssString.trim());
  }

  public save() {
    this.renderSettings.css = this.getCss();
    if (this.type == "category") {
      this.portraitRenderSettings.css = this.getPortraitCss();
      this.$emit("input", {
        renderSettings: this.renderSettings,
        portraitRenderSettings: this.portraitRenderSettings,
      });
    } else {
      this.$emit("input", this.renderSettings);
    }
    this.modal.hide();
  }
}
