





















import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { MaterialChannelSetting, Debug } from "@monowarestudios/js-util";
import SelectDeployable from "@/components/SelectDeployable.vue";

@Component({ components: { SelectDeployable } })
export default class MaterialChannelSettingEditor extends Vue {
  @Prop() value!: MaterialChannelSetting;
  @Prop() title!: string;
  @Prop() propClientID!: number;
  @Prop() debug!: Debug;
  @Prop() greyscale!: boolean;

  @Watch("value.textureID")
  private onTextureChange(newVal: number) {
    if (newVal) this.$emit("input", { ...this.value, colorID: 0 });
  }
  @Watch("value.colorID")
  private onColorChange(newVal: number) {
    if (newVal) this.$emit("input", { ...this.value, textureID: 0 });
  }
}
