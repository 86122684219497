import { Category, Option, StandaloneContentSetting } from "@monowarestudios/js-util";

// need to do this so set properly works with JSON.stringify and returns an array
export class JSONSet<T> extends Set<T> {
  toJSON() {
    return [...this];
  }
}

export interface IdLists {
  categorySets: string[]; // category set ids
  setCategories: string[]; // category internal names
  categoryOptions: string[]; // option internal names
}

export interface SetContainer {
  internalName: string;
  sets: JSONSet<string>; // internal names of CategorySets
}

export type ContainerRef = any; //Ref<InstanceType<typeof CategoryContainerVue>>;
export interface CategorySet {
  internalName: string;
  categories: JSONSet<string>; // internal names of SetCategories
  currentCategories: JSONSet<string>; // set of internal names of current categories
  containerRef?: ContainerRef;
  isVisible: boolean;
  classes?: string[];
  styles?: string;
  needThisCategorySetVisibleToShow: IdLists;
  needThisCategorySetNotVisibleToShow: IdLists;
  containers: JSONSet<string>
  standaloneTexts: JSONSet<string>
  standaloneImages: JSONSet<string>
  standaloneForms: JSONSet<string>;
  standaloneCartButtons: JSONSet<string>;
  standaloneCartSummary: JSONSet<string>;
  standaloneCurrencySelector: JSONSet<string>;
  standalonePrint: JSONSet<string>;
  financeComponents: JSONSet<string>;
  standaloneModals: JSONSet<string>;
  showCartIcon: boolean
  showDataSheet: boolean
}

export type CategoryViewRef = any; // Ref<InstanceType<typeof CategoryView>>;
export interface SetCategory {
  categoryInternalName: string;
  category?: Category;
  options: JSONSet<string>; // internal names of CategoryOptions
  categoryViewRef?: CategoryViewRef;
  classes?: string[];
  styles?: string;
  isVisible: boolean;
  needThisCategoryVisibleToShow: IdLists;
  needThisCategoryNotVisibleToShow: IdLists;
}

export type OptionViewRef = any; //Ref<InstanceType<typeof OptionView>>;
export interface CategoryOption {
  optionInternalName: string;
  option?: Option;
  categoryId: string; // internal name of owning category
  optionViewRef?: OptionViewRef;
  classes?: string[];
  styles?: string;
  isVisible: boolean;
  isSelected: boolean;
  isEnabled: boolean;
  needThisOptionVisibleToShow: IdLists;
  needThisOptionNotVisibleToShow: IdLists;
  needThisOptionSelectedToShow: IdLists;
  needThisOptionNotSelectedToShow: IdLists;
  needThisOptionEnabledToShow: IdLists;
  needThisOptionNotEnabledToShow: IdLists;
}
