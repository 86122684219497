









































import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import {
  FeatureFlags,
  Debug,
  floatingShareableModes,
  validationModes,
  Requests,
} from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";

@Component({ components: { BModalWrapper } })
export default class ConfigFeatureFlags extends Vue {
  @Prop() debug!: Debug;
  @Prop() value!: FeatureFlags;
  @Ref() modal!: BModalWrapper;
  model: FeatureFlags = {
    useCartIcon: false,
    useHWNotif: false,
    floatingShareables: false,
    hasSharing: false,
    goToCart: false,
    floatingShareablesMode: "text",
    validationsMode: "product-info",
    checkoutConfirmationMode: "post-checkout",
    checkoutNotificationString: "",
    checkoutConfirmation: false,
    productInfoShareable: false,
    swapMobileViewsOnClick: false,
    useMobileModalDropdowns: true,
    shareSettings: {
      useModal: false,
      useNativeMobileShare: false,
      showCopyButton: false,
      showFBButton: false,
      showTwitterButton: false,
      showLinkedInButton: false,
      showPinterestButton: false,
      showInstagramButton: false,
      showPrintButton: false,
    },
    includeLoadingScreenAsset: false,
    loadingScreenAssetUrl: ""
  };
  fileOptions: any[] = []

  get modes() {
    return floatingShareableModes;
  }
  get vModes() {
    return validationModes;
  }
  async getAssets() {
    const req = await Requests.list("texture")
    const [assetObjs, success] = req

    if (!success || typeof assetObjs === 'string') {
      return []
    }

    const assets = assetObjs.map((assetObj) => {
      return {
        value: assetObj.path,
        text: assetObj.name
      } as {
        value: string;
        text: string;
      }
    })

    const filteredAssets = assets.filter((asset) => {
      return asset.value.includes(".gif")
    })

    this.fileOptions = filteredAssets
  }

  public show() {
    this.model = JSON.parse(JSON.stringify(this.value));
    this.getAssets()
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  public save() {
    this.$emit("input", this.model);
    this.hide();
  }
}
