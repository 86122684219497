




























import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";
import {
  AnimationSetting,
  Animation,
  Configurator,
} from "@monowarestudios/js-util";
@Component
export default class AnimationSettingsView extends Vue {
  @Prop() value!: AnimationSetting[];
  @Prop() configurator!: Configurator;
  private selectedAnimation: Animation | null = null;
  private selectedAnimationSettings: AnimationSetting = AnimationSetting.fromData(
    {}
  );
  private animationSettings: AnimationSetting[] = [];

  private selectAnimation(index: number) {
    this.selectedAnimationSettings = this.animationSettings[index];
    this.selectedAnimation =
      this.animations.find(
        a => a.uid == this.selectedAnimationSettings!.uid
      )! || {};
  }

  private get animations(): Animation[] {
    return this.configurator ? this.configurator.animations : [];
  }

  private copyAnimation(source: Animation) {
    this.animationSettings.push(AnimationSetting.fromData(source));
  }
  @Watch("selectedAnimation")
  private updateAnimation() {
    this.selectedAnimationSettings!.uid = this.selectedAnimation!.uid;
  }

  private addAnimation() {
    this.animationSettings.push(
      AnimationSetting.fromData({
        name: "New Animation",
        speed: 1,
        cycleMode: "one",
        seekTo: 0,
      })
    );
    this.selectedAnimationSettings = this.animationSettings[
      this.animationSettings.length - 1
    ];
  }

  private mounted() {
    this.onUpdate();
  }

  @Watch("value")
  private onUpdate() {
    this.animationSettings = this.value;
  }

  private deleteAnimation(index: number) {
    this.animationSettings.splice(index, 1);
    this.$forceUpdate();
  }

  private async save() {
    this.$emit("input", this.animations);
  }
}
