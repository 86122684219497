import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import {
  BFormTextarea,
  BButton,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BFormSelect,
  BTable,
  ModalPlugin,
  BPagination,
  BCollapse,
  CollapsePlugin,
  CardPlugin,
  BFormTimepicker,
  BFormDatepicker,
  DropdownPlugin,
  BRow,
  BCol,
  BCardHeader,
  VBTooltip,
  BFormRadioGroup,
  BFormRadio,
} from "bootstrap-vue";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Multiselect from "vue-multiselect";
import Treeselect from "@riophae/vue-treeselect";
import draggable from "vuedraggable";
import LoadScript from "vue-plugin-load-script";
import VueCompositionApi from "@vue/composition-api";
import { createPinia, PiniaVuePlugin } from "pinia";

Vue.component("draggable", draggable);
Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.config.ignoredElements = ["mw-configurator"];
Vue.component("b-form-group", BFormGroup);
Vue.component("b-button", BButton);
Vue.component("b-form-textarea", BFormTextarea);
Vue.component("b-form-input", BFormInput);
Vue.component("b-form-file", BFormFile);
Vue.component("b-form-select", BFormSelect);
Vue.component("b-form-checkbox", BFormCheckbox);
Vue.component("b-form-timepicker", BFormTimepicker);
Vue.component("b-form-datepicker", BFormDatepicker);
Vue.component("b-table", BTable);
Vue.component("b-pagination", BPagination);
Vue.component("b-collapse", BCollapse);
Vue.component("b-row", BRow);
Vue.component("b-col", BCol);
Vue.component("multiselect", Multiselect);
Vue.component("treeselect", Treeselect);
Vue.component("b-card-header", BCardHeader);
Vue.component("b-form-radio-group", BFormRadioGroup);
Vue.component("b-form-radio", BFormRadio);
Vue.directive("b-tooltip", VBTooltip);
Vue.use(DropdownPlugin);
Vue.use(ModalPlugin);
Vue.use(CollapsePlugin);
Vue.use(CardPlugin);
Vue.use(LoadScript);
Vue.use(VueCompositionApi);

const pinia = createPinia();
Vue.use(PiniaVuePlugin);

new Vue({
  pinia,
  router,
  render: h => h(App),
  async mounted() {
    this.$loadScript(process.env.VUE_APP_CONFIGURATOR_URL as string);
  },
}).$mount("#app");
