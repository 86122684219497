












import { Component, Prop, Vue } from "vue-property-decorator";
import { Debug, OpacitySetting } from "@monowarestudios/js-util";
import SelectDeployable from "@/components/SelectDeployable.vue";

@Component({ components: { SelectDeployable } })
export default class OpacityEditor extends Vue {
  @Prop() value!: OpacitySetting;
  @Prop() debug!: Debug;
  @Prop() propClientID!: number;
}
