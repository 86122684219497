





















import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { StandaloneContentSetting, Debug } from "@monowarestudios/js-util";
import StandaloneTextEditor from "@/components/product_manager_views/StandaloneTextEditor.vue";

@Component({ components: { StandaloneTextEditor } })
export default class StandaloneContentSettingEditor extends Vue {
  @Prop() value!: StandaloneContentSetting[];
  @Prop() propClientID!: number;
  @Prop() debug!: Debug;
  @Prop() availableStandaloneTexts!: StandaloneContentSetting[];
  private standaloneTexts: StandaloneContentSetting[] = [];
  private selected = 0;
  private standaloneToAddName = "";
  private standaloneToAdd: StandaloneContentSetting = {
    visible: false,
    content: "",
    internalName: "",
  };

  @Watch("standaloneToAddName")
  private onSelect() {
    this.standaloneToAdd = this.availableStandaloneTexts.find(
      a => a.internalName == this.standaloneToAddName
    )!;
  }

  private get validOptions() {
    return this.availableStandaloneTexts
      .filter(
        a =>
          !this.standaloneTexts
            .map(s => s.internalName)
            .includes(a.internalName)
      )
      .map(a => a.internalName);
  }

  private addStandaloneText() {
    if (
      (this.availableStandaloneTexts && this.standaloneToAdd.internalName) ||
      !this.availableStandaloneTexts
    ) {
      this.standaloneTexts.push(
        JSON.parse(JSON.stringify(this.standaloneToAdd))
      );
      this.selected = this.standaloneTexts.length - 1;
      this.standaloneToAdd = {
        visible: false,
        content: "",
        internalName: "",
      };
    }
  }

  private deleteStandaloneText(index: number) {
    this.standaloneTexts.splice(index, 1);
    this.selected = this.standaloneTexts.length - 1;
    this.$forceUpdate();
  }
  @Watch("value")
  private onValue() {
    this.standaloneTexts = JSON.parse(JSON.stringify(this.value));
    this.selected = this.standaloneTexts.length - 1;
  }
  private mounted() {
    this.onValue();
  }
  private async save() {
    this.$emit("input", this.standaloneTexts);
  }

  private selectStandaloneText(index: number) {
    this.selected = index;
  }
  private copyStandaloneText(source: StandaloneContentSetting) {
    this.standaloneTexts.push(JSON.parse(JSON.stringify(source)));
    this.selected = this.standaloneTexts.length - 1;
  }
}
