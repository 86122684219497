








































































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import BModalWrapper from './BModalWrapper.vue';
import { CartConfig, Debug, Requests, ShowAs } from '@monowarestudios/js-util'

interface IMessage {
  internalName: string;
  message: string;
  link: string;
}

@Component({ components: { BModalWrapper } })
export default class EditCartView extends Vue {
  @Prop() debug!: Debug;
  @Prop() propClientID!: number;

  @Ref() modal!: BModalWrapper;

  status = ""
  currentMessages: IMessage[] = [];
  selectedMessages: IMessage[] = [];
  currentMessage: IMessage | null = null;
  newMessage: IMessage = {
    internalName: "",
    message: "",
    link: ""
  };

  cartConfig: CartConfig = {
    id: -1,
    clientId: -1,
    itemList: {
      allowQuantityChange: false,
      canChangeAddonQuantity: false,
      canCollapseAddons: false,
      canCollapseOptions: false,
      includeQuantityButtons: false,
      removeItemIconOnly: false,
      showPriceBreakdown: false,
      showPriceBreakdownAsTooltip: false,
      titleAsLink: false,
      showAs: ShowAs.Accordion
    },
    layout: "",
    canEditOrderNotes: false,
    includeOrderNotes: false,
    orderSummary: {
      includePricePreTaxes: false,
      includeTaxes: false,
      includeTotal: false,
      messages: []
    }
  }

  saveMessage() {
    if (!this.currentMessage) return

    this.cartConfig.orderSummary.messages = [...this.cartConfig.orderSummary.messages.filter(m => m.internalName !== this.currentMessage?.internalName), this.currentMessage]
  }
  deleteMessage() {
    if (!this.currentMessage) return

    this.cartConfig.orderSummary.messages = [...this.cartConfig.orderSummary.messages.filter(m => m.internalName !== this.currentMessage?.internalName)]
  }

  addMessage() {
    const alreadyExists = this.cartConfig.orderSummary.messages.find(m => m.internalName === this.newMessage.internalName) != undefined

    if (alreadyExists) return

    this.selectedMessages.push(this.newMessage)
    this.currentMessages.push(this.newMessage)
    this.newMessage = {
      internalName: "",
      message: "",
      link: ""
    }
  }

  get messageOptions() {
    return this.cartConfig.orderSummary.messages.map(m => ({
      value: m,
      text: m.internalName
    }))
  }

  public async show(id: number) {
    try {
      const [res, success] = await Requests.get("cartConfig", id)
      if (success) {
        this.cartConfig = res as CartConfig
        this.currentMessages = [...this.cartConfig.orderSummary.messages]
        this.selectedMessages = [...this.cartConfig.orderSummary.messages]
      }
    } catch (e) {
      console.error(e);
    }

    this.modal.show()
  }
  public async hide(id: number) {
    this.modal.hide()
  }

  async onSave() {
    this.cartConfig.orderSummary.messages = this.selectedMessages
    try {
      const [_data, success] = await Requests.update("cartConfig", this.cartConfig)

      if (success) {
        return this.status = " Saved"
      }

      return this.status = " Failed to Save"
    } catch (error) {
      console.error(error);
    }
  }

  async deploy() {
    const [_data, success] = await Requests.deploy("cartConfig", this.cartConfig.id)

    if (success) {
      return this.status = "Deployed"
    }

    return this.status = "Failed to deploy"
  }

}
