


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Config, ReferenceTypes, StandaloneReference } from '@monowarestudios/js-util';
import StandaloneModalEditor from './StandaloneModalEditor.vue';

@Component({
  components: {
    StandaloneModalEditor
  }
})
export default class StandaloneModalSettingEditor extends Vue {
  @Prop() value!: StandaloneReference[]
  @Prop() availableStandaloneModals!: StandaloneReference[]
  @Prop() config!: Config
  private standaloneModals: StandaloneReference[] = []
  private selected = 0
  private standaloneToAddName = ''
  private standaloneToAdd: StandaloneReference = {
    internalName: '',
    buttonText: '',
    targetInternalName: '',
    type: ReferenceTypes.TEXT
  }


  get validOptions() {
    const options = [
      ...this.getTextNames,
      ...this.getImageNames,
      ...this.getCategoryNames,
      ...this.getContainerNames
    ]



    return options.filter(o => {
      return !this.standaloneModals.map(m => m.internalName).includes(o)
    })
  }

  get getTextNames() {
    return this.config.standalone.text.map(t => t.internalName)
  }
  get getImageNames() {
    return this.config.standalone.image.map(i => i.internalName)
  }
  get getCategoryNames() {
    return this.config.categories.map(c => c.internalName)
  }
  get getOptionNames() {
    const categories = this.config.categories

    return categories.flatMap((c) => {
      return c.options.map((o) => {
        return `${c.internalName}::${o.internalName}`
      })
    })
  }
  get getContainerNames() {
    return this.config.containers.map(c => c.internalName)
  }

  addStandaloneModal() {
    this.standaloneModals.push(
      JSON.parse(
        JSON.stringify(this.standaloneToAdd)
      )
    )
    this.selected = this.standaloneModals.length -1
  }
  selectStandaloneModal(index: number) {
    this.selected = index
  }
  deleteStandaloneModal(index: number) {
    this.standaloneModals.splice(index, 1)
    this.selected = this.standaloneModals.length - 1
    this.$forceUpdate()
  }
  copyStandaloneModal(modal: StandaloneReference) {
    this.standaloneModals.push(
      JSON.parse(
        JSON.stringify(
          modal
        )
      )
    )

    this.selected = this.standaloneModals.length - 1
  }

  save() {
    this.$emit('input', this.standaloneModals)
  }

  @Watch("value")
  private onValue() {
    this.standaloneModals = JSON.parse(JSON.stringify(this.value || []));
    this.selected = this.standaloneModals.length - 1;
  }
  private mounted() {
    this.onValue();
  }
}
