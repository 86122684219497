

























import { Component, Vue, Ref } from "vue-property-decorator";
import Cookies from "js-cookie";
import { Requests, Debug, Network } from "@monowarestudios/js-util";
import createAuth0Client, { Auth0Client, User } from "@auth0/auth0-spa-js";
@Component({
})
export default class App extends Vue {
  private loggedin = false;
  private status = "";
  private monoware = true;
  private debug = new Debug();
  private auth0Client: Auth0Client | null = null;
  private user: User | undefined = undefined;

  private toggle() {
    this.monoware = !this.monoware;
  }
  private async created() {
    Network.baseOverride = process.env.VUE_APP_BACKEND_LOCATION as string;
    Network.publicImageLambda = process.env.VUE_APP_PUBLIC_IMAGES_URL as string;
    this.status = "";
    Network.handlers[401] = (res: Response) => {
      this.logout();
    };
    Network.handlers[404] = (res: Response) => {
      if (res.url.includes("/browse")) {
        return [{ success: false, data: "No results found" }, res.headers];
      } else {
        return [
          { success: false, data: "Error completing request" },
          new Headers(),
        ];
      }
    };

    this.auth0Client = await createAuth0Client({
      domain: process.env.VUE_APP_AUTH0_DOMAIN as string,
      client_id: process.env.VUE_APP_AUTH0_CLIENTID as string,
      redirect_uri: window.location.origin,
    });

    // check login
    let token = Cookies.get("mwtoken"); // the first req we issue will tell us if this is expired or not
    if (token && token != "undefined") {
      this.user = JSON.parse(atob(token.split(".")[1]));
    } else {
      token = await this.login(); // either redirect to login or handle incoming redirect
    }
    Cookies.set("mwtoken", token);
    Requests.token = token;
    this.loggedin = true;
    this.$router.push(window.location.pathname || "/");
  }

  private async login() {
    try {
      // If the user is returning to the app after authentication..
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        // handle the redirect and retrieve tokens
        await this.auth0Client!.handleRedirectCallback();
        this.loggedin = await this.auth0Client!.isAuthenticated();
        if (this.loggedin) {
          this.user = await this.auth0Client!.getUser();
          if (!/@monowarestudios.com$/.test(this.user!.email || "")) {
            throw new Error("not monoware");
          }
          return (await this.auth0Client!.getIdTokenClaims())!.__raw || "";
        } else {
          this.auth0Client!.loginWithRedirect();
        }
      } else {
        this.auth0Client!.loginWithRedirect();
      }
    } catch (e) {
      this.auth0Client!.loginWithRedirect();
    }
  }

  private logout() {
    Cookies.remove("mwtoken");
    this.auth0Client!.logout({
      returnTo: window.location.origin,
    });
  }

}
