import { render, staticRenderFns } from "./StandaloneNavEditor.vue?vue&type=template&id=54321431&scoped=true&lang=pug&"
import script from "./StandaloneNavEditor.vue?vue&type=script&lang=ts&"
export * from "./StandaloneNavEditor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54321431",
  null
  
)

export default component.exports