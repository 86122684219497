














import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import { Config, Debug, Confirmation } from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";
import EditConfirmation from "./EditConfirmation.vue";

@Component({ components: { BModalWrapper, EditConfirmation } })
export default class ConfigConfirmations extends Vue {
  @Prop() debug!: Debug;
  @Prop() value!: Config[];
  @Ref() modal!: BModalWrapper;
  private confirmations: Confirmation[] = [];

  public show() {
    this.confirmations = JSON.parse(JSON.stringify(this.value));
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  public save() {
    this.$emit("input", this.confirmations);
    this.hide();
  }

  private addConfirmation() {
    this.confirmations.push({
      internalName: "",
      required: false,
      text: "",
      type: "checkbox",
      value: false,
    });
  }

  private deleteConfirmation(index: number) {
    this.confirmations.splice(index, 1);
  }
}
