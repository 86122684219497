import { defineStore } from "pinia";
import { CategorySet, SetContainer } from "./StoreTypes";

interface StoreState {
  containers: Map<string, SetContainer>;
}

export const useSetContainerStore = defineStore("SetContainerStore", {
  state: (): StoreState => {
    return {
      containers: new Map(),
    };
  },
  getters: {
    getContainer(state) {
      return (containerInternalName: string) => {
        return state.containers.get(containerInternalName);
      };
    },
    getContainers(state) {
      return (internalNames: string[]) => {
        const containers: SetContainer[] = [];
        state.containers.forEach((c: SetContainer) => {
          if (!internalNames.includes(c.internalName)) return;

          containers.push(c);
        });

        return containers;
      };
    },
  },
  actions: {
    addContainer(container: SetContainer) {
      this.containers.set(container.internalName, container);
    },
    addContainers(containers: SetContainer[]) {
      containers.forEach(c => this.addContainer(c));
    },
    removeContainer(internalName: string) {
      this.containers.delete(internalName);
    },
    addSetToContainer(containerInternalName: string, set: CategorySet) {
      const container = this.getContainer(containerInternalName);

      container?.sets.add(set.internalName);
    },
  },
});
