






















































import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import {
  APISettings,
  Configurator,
  Debug,
  GeometrySetting,
  MaterialSetting,
  MaterialChannelSetting,
  NormalSetting,
  AOPBRSetting,
  EmitColorSetting,
  MaterialChannelGrayscaleSetting,
  Rotation,
  XYZ,
  OpacitySetting,
AnnotationSetting,
} from "@monowarestudios/js-util";
import {
  MaterialChannel,
  Opacity,
  CameraSettingsView,
  NormalEditor,
  AopbrEditor,
} from "./api_data_components";
import BModalWrapper from "@/components/BModalWrapper.vue";
import AnimationSettingsView from "./api_data_components/AnimationSettingsView.vue";
@Component({
  components: {
    MaterialChannel,
    "opacity-setting": Opacity,
    BModalWrapper,
    CameraSettingsView,
    AnimationSettingsView,
  NormalEditor,
  AopbrEditor,
  },
})
export default class OptionApiConfiguration extends Vue {
  private selectedMaterial = 0;
  private status = "";
  private optionData: APISettings = APISettings.fromData({});
  private showList: string[] = [];
  private hideList: string[] = [];
  private showAnnotationList: string[] = [];
  private hideAnnotationList: string[] = [];
  private gotoAnnotationList: string[] = [];
  @Prop() configurator!: Configurator;
  @Prop() value!: APISettings;
  @Prop() clientID!: number;
  @Prop() debug!: Debug;
  @Ref() MetalnessPBR!: MaterialChannel;
  @Ref() RoughnessPBR!: MaterialChannel;
  @Ref() Specular!: MaterialChannel;
  @Ref() Opacity!: MaterialChannel;
  @Ref() AlbedoPBR!: MaterialChannel;
  @Ref() EmitColor!: MaterialChannel;
  @Ref() Normal!: NormalEditor;
  @Ref() AOPBR!: AopbrEditor;
  @Ref() modal!: BModalWrapper;
  @Prop() materialList!: Record<string, any>;
  @Prop() geometryList!: Record<string, any>;
  @Prop() geometryTree!: Record<string, any>;
  @Prop() annotations!: any[];

  private get materials() {
    return Object.keys(this.materialList)
  }
  private get geometries() {
    return Object.values(this.geometryList)
  }
  private normalizer(node: any) {
    return {
      id: node.name,
      label: node.name,
      children: node.children,
    }
  }
  private get materialName() {
    return this.optionData.materials[this.selectedMaterial]?.name || "";
  }
  private set materialName(val: string) {
    this.optionData.materials[this.selectedMaterial].name = val;
  }
  private get albedoPBR() {
    return (
      this.optionData.materials[this.selectedMaterial]?.channels.albedoPBR || {}
    );
  }
  private set albedoPBR(val: MaterialChannelSetting) {
    this.optionData.materials[this.selectedMaterial].channels.albedoPBR = val;
  }
  private get emitColor() {
    return (
      this.optionData.materials[this.selectedMaterial]?.channels.emitColor || {}
    );
  }
  private set emitColor(val: EmitColorSetting) {
    this.optionData.materials[this.selectedMaterial].channels.emitColor = val;
  }
  private get opacity() {
    return (
      this.optionData.materials[this.selectedMaterial]?.channels.opacity || {}
    );
  }
  private set opacity(val: OpacitySetting) {
    this.optionData.materials[this.selectedMaterial].channels.opacity = val;
  }
  private get normal() {
    return (
      this.optionData.materials[this.selectedMaterial]?.channels.normalMap || {}
    );
  }
  private set normal(val: NormalSetting) {
    this.optionData.materials[this.selectedMaterial].channels.normalMap = val;
  }
  private get aopbr() {
    return (
      this.optionData.materials[this.selectedMaterial]?.channels.aOPBR || {}
    );
  }
  private set aopbr(val: AOPBRSetting) {
    this.optionData.materials[this.selectedMaterial].channels.aOPBR = val;
  }
  private get specular() {
    return (
      this.optionData.materials[this.selectedMaterial]?.channels.specularF0 ||
      {}
    );
  }
  private set specular(val: MaterialChannelGrayscaleSetting) {
    this.optionData.materials[this.selectedMaterial].channels.specularF0 = val;
  }
  private get roughnessPBR() {
    return (
      this.optionData.materials[this.selectedMaterial]?.channels.roughnessPBR ||
      {}
    );
  }
  private set roughnessPBR(val: MaterialChannelGrayscaleSetting) {
    this.optionData.materials[
      this.selectedMaterial
    ].channels.roughnessPBR = val;
  }
  private get metalnessPBR() {
    return (
      this.optionData.materials[this.selectedMaterial]?.channels.metalnessPBR ||
      {}
    );
  }
  private set metalnessPBR(val: MaterialChannelGrayscaleSetting) {
    this.optionData.materials[
      this.selectedMaterial
    ].channels.metalnessPBR = val;
  }

  public async show() {
    this.showList = [];
    this.hideList = [];
    this.showAnnotationList = [];
    this.hideAnnotationList = [];
    this.gotoAnnotationList = [];
    this.optionData = APISettings.fromData(this.value);
    this.value.geometries.forEach(geometry => {
      if (geometry.action === "show") {
        this.showList.push(geometry.name);
      }
      if (geometry.action === "hide") {
        this.hideList.push(geometry.name);
      }
    });
    this.value.annotations.forEach(annotation => {
      if (annotation.action === "show") {
        this.showAnnotationList.push(annotation.name);
      }
      if (annotation.action === "hide") {
        this.hideAnnotationList.push(annotation.name);
      }
      if (annotation.action === "goto") {
        this.gotoAnnotationList.push(annotation.name);
      }
    });
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  private addMaterial() {
    this.optionData.materials.push(
      MaterialSetting.fromData({ name: "New Material" })
    );
    this.selectedMaterial = this.optionData.materials.length - 1;
  }

  private deleteMaterial(index: number) {
    this.optionData.materials.splice(index, 1);
    this.selectedMaterial = 0;
    this.$forceUpdate();
  }

  private async save() {
    // TODO: need to translate the show/hides
    const geometries: GeometrySetting[] = [];
    const annotations: AnnotationSetting[] = []
    this.showList.forEach(name => {
      geometries.push(
        new GeometrySetting(
          name,
          "show",
          new XYZ(-1, -1, -1),
          new Rotation(-1, -1, -1, -1)
        )
      );
    });
    this.hideList.forEach(name => {
      geometries.push(
        new GeometrySetting(
          name,
          "hide",
          new XYZ(-1, -1, -1),
          new Rotation(-1, -1, -1, -1)
        )
      );
    });
    this.showAnnotationList.forEach(name => {
      annotations.push(
        new AnnotationSetting(
          name,
          "show",
        )
      );
    });
    this.hideAnnotationList.forEach(name => {
      annotations.push(
        new AnnotationSetting(
          name,
          "hide",
        )
      );
    });
    this.gotoAnnotationList.forEach(name => {
      annotations.push(
        new AnnotationSetting(
          name,
          "goto",
        )
      );
    });

    this.optionData.geometries = geometries;
    this.optionData.annotations = annotations;
    this.$emit("input", this.optionData);
    this.hide();
  }

  private selectMaterial(index: number) {
    this.selectedMaterial = index;
  }
  private copyMaterial(source: MaterialSetting) {
    this.optionData.materials.push(MaterialSetting.fromData(source));
  }
}
