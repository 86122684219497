











































































import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { StandaloneFormSetting, Debug } from "@monowarestudios/js-util";
import Multiselect from "vue-multiselect";
@Component({ components: {Multiselect} })
export default class StandaloneFormSettingEditor extends Vue {
  @Prop() value!: StandaloneFormSetting[];
  @Prop() propClientID!: number;
  @Prop() debug!: Debug;
  @Prop() availableStandaloneFormSettings!: StandaloneFormSetting[];
  public standaloneForms: StandaloneFormSetting[] = [];
  public selected = 0;
  public showOrder = false;
  public formOptions = ["number", "text", "textArea", "file", "date", "color", "checkbox", "address", "radio", "select"];
  public get validOptions() {
    return this.availableStandaloneFormSettings
      .filter(
        a =>
          !this.standaloneForms
            .map(s => s.internalName)
            .includes(a.internalName)
      )
      .map(a => a.internalName);
  }

  

  public addStandaloneFormSetting() {
      this.standaloneForms.push({
        inputs: [],
        internalName: "",
        displayName: "",
      }
      );
      this.selected = this.standaloneForms.length - 1;
  }

  public deleteStandaloneFormSetting(index: number) {
    this.standaloneForms.splice(index, 1);
    this.selected = this.standaloneForms.length - 1;
    this.$forceUpdate();
  }
  @Watch("value")
  public onValue() {
    this.standaloneForms = JSON.parse(JSON.stringify(this.value));
    this.selected = this.standaloneForms.length - 1;
  }
  public countries = []
  public async mounted() {
    const res = await fetch("https://configurator.monowarestudios.com/country-data/countries.json");
    this.countries = (await res.json()).map((c:any) =>c.iso3);
    this.onValue();
  }
  public async save() {
    this.$emit("input", this.standaloneForms);
  }

  public selectStandaloneFormSetting(index: number) {
    this.selected = index;
  }
  public copyStandaloneFormSetting(source: StandaloneFormSetting) {
    this.standaloneForms.push(JSON.parse(JSON.stringify(source)));
    this.selected = this.standaloneForms.length - 1;
  }
  public addProperty() {
    this.standaloneForms[this.selected].inputs.push({
      displayName: "",
      propertyName: "",
      placeholder: "",
      pattern: "",
      options: [],
      type: "text",
      required: false,
      useToggle: false,
      addressSettings: {
        useSecondStreetAddress: false,
        useStateName: false,
        useCountryName: false,
        streetAddressOneMapping: "",
        streetAddressTwoMapping: "",
        cityMode: "",
        cityMapping: "",
        stateMapping: "",
        countryMapping: "",
        postalMapping: "",
        allowedCities: [],
        allowedStates: [],
        allowedCountries: [],
      }
    })
  }

  public addOption(index: number) {
    this.standaloneForms[this.selected].inputs[index].options.push({
      displayName: "",
      value:"",
    });
  }
  public removeOption(index: number, secondIndex:number) {
    this.standaloneForms[this.selected].inputs[index].options.splice(secondIndex, 1);
  }
  public removeProperty(i:number) {
    this.standaloneForms[this.selected].inputs.splice(i,1)
  }
}
