







// TODO: format the avialable options
import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import { Category, Debug } from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";

@Component({ components: { BModalWrapper } })
export default class ConfigSequenceCategory extends Vue {
  @Prop() debug!: Debug;
  @Prop() value!: string;
  @Prop() availableCategories!: Category[];
  @Ref() modal!: BModalWrapper;
  private model = "";

  public show() {
    this.model = JSON.parse(JSON.stringify(this.value));
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  public save() {
    this.$emit("input", this.model);
    this.hide();
  }
}
