

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { StandaloneContentSetting } from '@monowarestudios/js-util';


@Component
export default class StandaloneAddToCartSettingEditor extends Vue {
  @Prop() value!: StandaloneContentSetting[]
  @Prop() availableStandaloneButtons!: StandaloneContentSetting[]
  standaloneButtons: StandaloneContentSetting[] = []
  selected = 0

  addStandaloneButton() {
      this.standaloneButtons.push({
        internalName: ''
      })
      this.selected = this.standaloneButtons.length - 1
  }
  save() {
    this.$emit('input', this.standaloneButtons)
  }
  selectStandaloneButton(index: number) {
    this.selected = index
  }
  deleteStandaloneButton(index: number) {
    this.standaloneButtons.splice(index, 1)
    this.selected = this.standaloneButtons.length - 1
    this.$forceUpdate()
  }
  copyStandaloneButton(standaloneButton: StandaloneContentSetting) {
    this.standaloneButtons.push(
      JSON.parse(
        JSON.stringify(
          standaloneButton
        )
      )
    )

    this.selected = this.standaloneButtons.length - 1
  }

  @Watch("value")
  private onValue() {
    this.standaloneButtons = JSON.parse(JSON.stringify(this.value || []));
    this.selected = this.standaloneButtons.length - 1;
  }
  private mounted() {
    this.onValue();
  }
}
