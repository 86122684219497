


























import { Component, Watch, Prop, Vue } from "vue-property-decorator";

import { Validation } from "@monowarestudios/js-util";
import ValidationConditionEditor from "./ValidationConditionEditor.vue";
import ValidationStateCheckEditor from "./ValidationStateCheckEditor.vue";

@Component({components:{ValidationConditionEditor}})
export default class StandaloneNavEditor extends Vue {
  @Prop() value!: Validation;
  @Prop() options!: string[];
  private validation: Validation = new Validation({})

  @Watch("value")
  private onUsageChange() {
    this.validation = JSON.parse(JSON.stringify(this.value));
  }

  private mounted() {
    this.onUsageChange();
  }

  private save() {
    this.$emit("input", this.validation);
  }
  private addCondition() {
      this.validation.conditions.push({
        key: "",
        reference: "",
        value: ""
      })
  }

  private deleteCondition(index: number) {
    this.validation.conditions.splice(index, 1);
    this.$forceUpdate();
  }
}
