











import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { Metafield, Requests } from "@monowarestudios/js-util";

@Component
export default class MetafieldView extends Vue {
  @Prop() value!: Metafield;
  @Prop() productExternalID!: string;
  @Prop() clientID!: number;
  private status = "";
  private metafield: Metafield = Metafield.fromData({});

  private mounted() {
    this.metafield = Metafield.fromData(this.value);
  }

  @Watch("value")
  private updateValue() {
    this.metafield = Metafield.fromData(this.value);
  }

  private async save() {
    try {
      const [data, success] = await (this.metafield.id == 0
        ? Requests.createProductMetadata(
            this.clientID,
            this.productExternalID,
            this.metafield
          )
        : Requests.updateProductMetadata(
            this.clientID,
            this.productExternalID,
            this.metafield
          ));
      if (success) {
        this.status = "Data saved!";
        this.metafield.id = this.metafield.id || (data as Metafield).id;
      } else {
        this.status = ("Failed to save data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }
}
