


















import { Component, Watch, Prop, Vue, Ref } from "vue-property-decorator";
import { Validation, Debug } from "@monowarestudios/js-util";
import ValidationEditor from "@/components/product_manager_views/ValidationEditor.vue";
import BModalWrapper from "@/components/BModalWrapper.vue";

@Component({ components: { BModalWrapper, ValidationEditor } })
export default class ConfigValidationEditor extends Vue {
  @Prop() value!: Validation[];
  @Prop() propClientID!: number;
  @Prop() options!: string[];
  @Ref() modal!: BModalWrapper;
  @Prop() debug!: Debug;
  private validations: Validation[] = [];
  private selected = 0;


  private addValidation() {
      this.validations.push(new Validation({}))
      this.selected = this.validations.length - 1;
  }

  private deleteValidation(index: number) {
    this.validations.splice(index, 1);
    this.selected = this.validations.length - 1;
    this.$forceUpdate();
  }
  @Watch("value")
  private onValue() {
    this.validations = JSON.parse(JSON.stringify(this.value));
  }
  private mounted() {
    this.onValue();
  }
  private save() {
    this.$emit("input", this.validations);
  }

  private selectValidation(index: number) {
    this.selected = index;
  }
  private copyValidation(source: Validation) {
    this.validations.push(JSON.parse(JSON.stringify(source)));
    this.selected = this.validations.length - 1;
  }

  public show() {
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }
}
