


















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  Category,
  VariantSet,
  EmptyVariantSet,
  EmptyVariant,
} from "@monowarestudios/js-util";
import VariantView from "./VariantView.vue";

@Component({ components: { VariantView } })
export default class VariantSetView extends Vue {
  @Prop() value!: VariantSet;
  @Prop() categories!: Category[];
  private variantSet: VariantSet = EmptyVariantSet();

  @Watch("value")
  private updateValue() {
    this.variantSet = JSON.parse(JSON.stringify(this.value));
  }

  private add() {
    this.variantSet.variants.push(EmptyVariant());
    this.onUpdate();
  }

  private remove(index: number) {
    this.variantSet.variants.splice(index, 1);
    this.onUpdate();
  }

  private mounted() {
    this.updateValue();
  }

  private onUpdate() {
    this.$emit("input", this.variantSet);
  }

  private copy(index: number) {
    this.variantSet.variants = index ? this.variantSet.variants = [
      ...this.variantSet.variants.slice(0, index),
      JSON.parse(JSON.stringify(this.variantSet.variants[index])),
      ...this.variantSet.variants.slice(index + 1),
    ]:
      [...this.variantSet.variants, JSON.parse(JSON.stringify(this.variantSet.variants[index]))]
    this.$forceUpdate();
  }
}
