











 
import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";
import { Requests, Texture, EmptyTexture, Debug,  PresignedUrlInfo } from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";
import ImageUpload from "@/components/ImageUpload.vue"

@Component({ components: { BModalWrapper, ImageUpload } })
export default class EditTextureView extends Vue {
  @Prop() debug!: Debug;
  @Prop() propClientID!: number;
  @Ref() modal!: BModalWrapper;
  private status = "";
  private texture: Texture = EmptyTexture();
  private imageUpload: PresignedUrlInfo = {key: "", url: "", uploadUrl: "", expires: new Date()}
  private preview = "";

  private async getData(id: number) {
    try {
      const [data, success] = await Requests.get("texture", id);
      if (success) {
        this.texture = data as Texture;
      } else {
        this.status = data as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }

  @Watch("imageUpload")
  private onImageUpload(newVal: PresignedUrlInfo) {
    this.texture.path = newVal.url
    this.texture.key = newVal.key
  }

  public show(id: number) {
    this.imageUpload = {key: "", url: "", uploadUrl: "", expires: new Date()}
    if (id != 0) {
      this.getData(id);
    } else {
      this.texture = { ...EmptyTexture(), clientID: this.propClientID };
    }
    this.modal.show();
    if (this.texture.path) this.preview = this.texture.path
  }

  public hide() {
    this.modal.hide();
  }

  private cancel() {
    this.hide();
  }

  private async save() {
    this.status = "";
    try {
      if (this.texture.path && this.texture.key) {
        const [data, success] = await Requests.update("texture", this.texture);
        if (success) {
          this.status = "Data saved!";
          this.$emit("created");
        } else {
          this.status = ("Failed to save data!\n" + data) as string;
        }
      } else {
        this.status = "Please upload a file"
      }
    } catch (err) {
      this.status = err as string;
    }
  }

}
