










import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";
import { Debug, Metafield, Requests } from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";
import MetafieldView from "./MetafieldView.vue";

@Component({ components: { MetafieldView, BModalWrapper } })
export default class ProductMetafieldEditor extends Vue {
  @Prop() debug!: Debug;
  @Prop() productExternalID!: string;
  @Prop() clientID!: number;
  @Ref() modal!: BModalWrapper;
  private metafields: Metafield[] = [];
  private status = "";

  public async show() {
    this.status = "";
    try {
      const [data, success] = await Requests.loadProductMetadata(
        this.clientID,
        this.productExternalID
      );
      if (success) {
        this.metafields = (data as any).metafields.map((d: any) =>
          Metafield.fromData(d)
        );
      } else {
        this.status = ("Failed to load data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  private add() {
    this.metafields.push(Metafield.fromData({}));
  }

  private async remove(index: number) {
    this.status = "";
    try {
      const [data, success] = await Requests.deleteProductMetadata(
        this.clientID,
        this.productExternalID,
        this.metafields[index]
      );
      if (success) {
        this.metafields.splice(index, 1);
      } else {
        this.status = ("Failed to delete data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }

  private cancel() {
    this.hide();
  }
}
