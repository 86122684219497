











































import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import {
  Requests,
  Debug,
  Config,
  Product,
  BakedConfig,
  Configurator,
  EmptyProduct,
  PresignedUrlInfo,
} from "@monowarestudios/js-util";
import ConfigDetailsView from "@/components/product_manager_views/ConfigDetailsView.vue";
import ProductDetailsView from "@/components/product_manager_views/ProductDetailsView.vue";

@Component({
  components: {
    ConfigDetailsView,
    ProductDetailsView,
  },
})
export default class ProductManager extends Vue {
  @Prop() debug!: Debug;
  @Ref() private readonly wrapper!: any;
  private configSource: BakedConfig | null = null;
  private status = "";
  private currentDeployedConfig = 0;
  private spinnyBit!: HTMLElement;
  private product: Product = EmptyProduct(); // default new product
  // toggles for pane extension
  private showConfig = false;
  private showProduct = false;
  private productImage = "";
  private storefrontToken = "";
  private backend = process.env.VUE_APP_BACKEND_LOCATION;
  private configurator: Configurator | null = null;
  private geometryTree: Record<string, any> = {}
  private geometryList: Record<string, any> = {}
  private materialList: Record<string, any> = {}
  private annotations: any[] = []
  private animationsList: Record<string, any> = {} // TODO: we dont use any yet
  private setToggles(showConfig: boolean, showProduct: boolean) {
    this.showConfig = showConfig;
    this.showProduct = showProduct;
  }

  private flattenTree(node: {name: string, instanceID: number, children: any[]}, flattened: Record<string, any>) {
    flattened[node.name] = node.instanceID
    node.children = node.children.filter((c: any) => c.type == "MatrixTransform")
    node.children?.forEach((child: any) => {
      this.flattenTree(child, flattened)
    })
  }

  private async mounted() {
    const productID = parseInt(this.$route.params.id);
    if (productID == 0) {
      this.product = {
        ...EmptyProduct(),
        clientID: parseInt(this.$route.params.client),
        id: 0,
      };
    } else {
      let [data, success] = await Requests.get("product", productID);
      if (success) {
        this.product = data;
        this.setToggles(true, false);
      } else {
        this.product = EmptyProduct();
        this.status = data as string;
      }
      [data, success] = await Requests.getProductCurrentDeployment(
        this.product
      );
      if (success) {
        this.currentDeployedConfig = data;
      } else {
        this.status = data as string;
      }
    }
  }

  // baseOverride used because wrapper is built with api.monowarestudios.com as its base
  // url with its own env var and we need to specify this ones backend location when
  // building for dev
  private getBaseOverride(): string {
    return process.env.NODE_ENV == "production"
      ? ""
      : (process.env.VUE_APP_BACKEND_LOCATION as string);
  }

  private async screenshot() {
    this.status = "";
    if (this.configurator) {
      const [err, result] = await this.configurator!.screenshot();
      let [data, success] = await Requests.getPresignedURL(
        process.env.VUE_APP_PUBLIC_IMAGE_SIGNED_URL as string,
        result.type,
        result.size
      );
      if (!success) {
        this.status = data as string;
      } else {
        let [imageUrl, successTwo] = await Requests.uploadPublicImage(
          data as PresignedUrlInfo,
          result
        );
        if (successTwo) this.productImage = imageUrl;
        else this.status = imageUrl;
      }
    } else {
      this.status = "not yet loaded kid be patient";
    }
  }

  private async reloadConfigurator(config: Config) {
    if (config.uid != "") {
      this.status = "Loading new bake...";
      this.wrapper._instance.clearConfiguratorInterval();
      this.configSource = null;
      try {
        const [data, success] = await Requests.bakeConfig(config);
        if (success) {
          (data as Config).uid = config.uid;
          this.status = "Configurator data loaded! MISO PLEASE WAIT";
          this.configSource = {
            config: { ...(data as Config), product: this.product },
            deploymentID: -1,
          } as any;
          setTimeout(async () => {
            const {configurator, materialList, geometryTree } = await this.wrapper._instance.getConfiguratorInstance();
            this.materialList = materialList.reduce((acc: any, curr: any) => ({ ...acc, [curr.name]: curr }), {}) as any;
            this.geometryTree = geometryTree;
            this.configurator = configurator;
            this.geometryList = {};
            this.flattenTree(this.geometryTree as any, this.geometryList);
            this.status = "LOADING DONE MISO YOU CAN DO STUFF NOW";
            console.log(this.materialList, materialList);
          }, 5000)
        } else {
          this.status = ("Failed to load data!\n" + data) as string;
        }
      } catch (err) {
        this.status = err as string;
      }
    } else {
      this.status = "No UID configured";
    }
  }

  private onError(data: string) {
    this.status = "Error in configurator: " + data;
  }
}
