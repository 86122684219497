



























import { StandaloneFinanceSetting, TimeType } from '@monowarestudios/js-util';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import StandaloneFinanceEditor from "./StandaloneFinanceEditor.vue";

@Component({
  components: {
    StandaloneFinanceEditor
  }
})
export default class StandaloneFinanceSettingEditor extends Vue {
  @Prop() value!: StandaloneFinanceSetting[]
  @Prop() availableStandaloneFinanceSettings!: StandaloneFinanceSetting[]
  standaloneFinanceSettings: StandaloneFinanceSetting[] = []
  selected = 0
  standaloneToAddName = ''
  standaloneToAdd: StandaloneFinanceSetting = this.defaultStandalone

  get defaultStandalone(): StandaloneFinanceSetting {
    return {
      internalName: '',
      includeTitle: true,
      title: '',
      includeProductInTitle: true,
      includePriceInTitle: true,
      estimatedPaymentsOptions: {
        endNumber: 0,
        incrementAmount: 0,
        startNumber: 0,
        type: TimeType.DAY
      },
      extendedWarrantyPrice: 0,
      extendedWarrantyPrices: [],
      frequencyOptions: [],
      includeDownPayment: true,
      includeDownPaymentAmountDuplicate: true,
      includeEstimatedPayments: true,
      includeExtendedWarranty: true,
      includeFrequency: true,
      includeRate: true,
      includeReview: true,
      includeSummary: true,
      miscTexts: [],
      startingDownPayment: 0,
      startingRate: 0
    }
  }

  get availableOptions() {
    return this.availableStandaloneFinanceSettingNames.filter(
      asfs => !this.standaloneFinanceSettings.map(sfs => sfs.internalName).includes(asfs)
    )
  }

  get availableStandaloneFinanceSettingNames() {
    return this.availableStandaloneFinanceSettings.map(sfs => sfs.internalName)
  }

  addStandaloneFinanceSetting() {
    if (
      (this.availableStandaloneFinanceSettings && this.standaloneToAdd.internalName)
      || !this.availableStandaloneFinanceSettings
    ) {
      this.standaloneFinanceSettings.push(
        JSON.parse(JSON.stringify(this.standaloneToAdd))
      )

      this.selected = this.standaloneFinanceSettings.length - 1
      this.standaloneToAdd = this.defaultStandalone
    }
  }

  selectStandaloneFinanceSetting(index: number) {
    this.selected = index
  }

  deleteStandaloneFinanceSetting(index: number) {
    this.standaloneFinanceSettings.splice(index, 1)
    this.selected = this.standaloneFinanceSettings.length - 1
    this.$forceUpdate()
  }

  copyStandaloneFinanceSetting(standalone: StandaloneFinanceSetting) {
    this.standaloneFinanceSettings.push(JSON.parse(JSON.stringify(standalone)))
    this.selected = this.standaloneFinanceSettings.length - 1
  }

  save() {
    this.$emit("input", this.standaloneFinanceSettings)
  }

  @Watch("value")
  onValue() {
    this.standaloneFinanceSettings = JSON.parse(JSON.stringify(this.value))
    this.selected = this.standaloneFinanceSettings.length - 1
  }

  mounted() {
    this.onValue()
  }
}
