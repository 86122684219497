

























































import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import {
  InterfaceSettings,
  Option,
  Category,
  Debug,
  SetContainer,
} from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";
import StandaloneSettingEditor from "@/components/product_manager_views/StandaloneSettingEditor.vue";
import useConfigStore from "@/stores/ConfigStore";

@Component({ components: { BModalWrapper, StandaloneSettingEditor } })
export default class OptionUIConfiguration extends Vue {
  private enable: any[] = []; // why did i do it like this???
  private disable: any[] = [];
  private select: any[] = [];
  private scrollIntoView: any[] = [];
  private unselect: any[] = [];
  private setActiveContainerSet: any[] = [];
  private uiStateSelectedConditions: any[] = [];
  private open: any[] = [];
  private close: any[] = [];
  private selectionPreventedMessage = "";
  private preventsSelection: any[] = [];
  private standaloneTexts: any[] = [];
  private disableInteraction: any[] = [];
  private enableInteraction: any[] = [];
  @Prop() configId!: number;
  @Prop() availableCategories!: Category[];
  @Prop() availableStandaloneTexts!: string[];
  @Prop() availableStandaloneImages!: string[];
  @Prop() value!: InterfaceSettings;
  @Prop() debug!: Debug;
  @Prop() category!: string;
  @Ref() modal!: BModalWrapper;
  private containers: any[] = [];
  private flags = {
    toggleShowsIfToggle: false,
    toggleHidesIfToggle: false,
  }
  private get containerSetRefs() {
    return this.containers.reduce(
        (acc: string[], curr: SetContainer) => [
          ...acc,
          ...[...curr.sets].reduce(
            (accTwo: string[], s) => [...accTwo, `#${curr.internalName}::${s}`],
            []
          ),
        ],
        []
      )
  }

  private get containersAndCategoriesAndOptionsEnable() {
    const containers = this.containers.map((c: SetContainer) => `#${c.internalName}`)
    

    const categoriesAndOptions = this.availableCategories
      ? (this.availableCategories?.map(cat => [
          ...cat.options?.map(
            (o: Option) => `${cat.internalName}::${o.internalName}`
          ),
          cat.internalName,
          `${cat.internalName}::*`,
          this.category == cat.internalName
            ? `${cat.internalName}::*!self`
            : null, // only need if it is our category
        ])
          .reduce((acc, curr) => [...acc, ...curr], [])
          .filter(i => i) as string[])
      : [];

    return [...containers, ...categoriesAndOptions];
  }

  private get containersAndCategoriesAndOptionsDisable() {
    return this.containersAndCategoriesAndOptionsEnable.filter(
      s => s && !this.enable.includes(s)
    );
  }

  private get optionsOnlySelect() {
    return this.containersAndCategoriesAndOptionsEnable.filter(s =>
      s!.includes("::")
    );
  }
  private get optionsOnlyUnselect() {
    return this.optionsOnlySelect.filter(s => !this.select.includes(s));
  }
  private get optionsOnlyEnableInteraction() {
    return this.containersAndCategoriesAndOptionsEnable.filter(s =>
      s!.includes("::")
    );
  }
  private get optionsOnlyDisableInteraction() {
    return this.optionsOnlyEnableInteraction.filter(s => !this.enableInteraction.includes(s));
  }

  private get dropdownsAndAccordions() {
    const categoriesAndOptions = this.availableCategories
      ? (this.availableCategories
          .filter(
            ac =>
              ac.renderSettings.renderType === "dropdown" ||
              ac.renderSettings.renderType === "accordion"
          )
          .map(cat => [
            ...cat.options
              .filter(o => o.renderSettings.renderType === "dropdown")
              .map((o: Option) => `${cat.internalName}::${o.internalName}`),
            cat.internalName,
            `${cat.internalName}::*`,
            this.category == cat.internalName
              ? `${cat.internalName}::*!self`
              : null, // only need if it is our category
          ])
          .reduce((acc, curr) => [...acc, ...curr], [])
          .filter(i => i) as string[])
      : [];

    return categoriesAndOptions;
  }

  private get dropdownsAndAccordionsOpen() {
    return this.dropdownsAndAccordions.filter(
      item => !this.close.includes(item)
    );
  }

  private get dropdownsAndAccordionsClose() {
    return this.dropdownsAndAccordions.filter(
      item => !this.open.includes(item)
    );
  }

  public async show() {
    // here we have a wonderful example of how i did something one way, way back when
    // and then modified it for something else,
    // and then just never rewrote the fucking thing
    // and instead just keep duplicating lines and shoving crap in here
    const configStore = useConfigStore();
    this.enable = this.value.enable;
    this.disable = this.value.disable;
    this.select = this.value.select;
    this.scrollIntoView = this.value.scrollIntoView;
    this.unselect = this.value.unselect;
    this.selectionPreventedMessage = this.value.selectionPreventedMessage;
    this.standaloneTexts = this.value.standaloneTexts;
    this.preventsSelection = this.value.preventsSelection;
    this.setActiveContainerSet = this.value.setActiveContainerSet;
    this.disableInteraction = this.value.disableInteraction;
    this.enableInteraction = this.value.enableInteraction;
    this.flags = this.value.flags;
    //this.uiStateSelectedConditions = this.value.uiStateSelectedConditions;
    this.containers = configStore.getContainers(this.configId);
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  private async save() {
    const processed = this.value;
    processed.enable = this.enable;
    processed.disable = this.disable;
    processed.select = this.select;
    processed.selectionPreventedMessage = this.selectionPreventedMessage;
    processed.preventsSelection = this.preventsSelection;
    processed.standaloneTexts = this.standaloneTexts;
    processed.scrollIntoView = this.scrollIntoView;
    processed.unselect = this.unselect;
    processed.setActiveContainerSet = this.setActiveContainerSet;
    processed.open = this.open;
    processed.close = this.close;
    processed.disableInteraction = this.disableInteraction;
    processed.enableInteraction = this.enableInteraction;
    processed.flags = this.flags;
    // TODO!: note that THIS NEEDS TO BE FIXED before being renabled.
    //processed.uiStateSelectedConditions = this.uiStateSelectedConditions;
    this.$emit("input", processed);
    this.hide();
  }
}
