

























import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import CameraSettingsView from "../option/api_data_components/CameraSettingsView.vue";
import {
  Debug,
  OptionDefaults,
  EmptyOptionDefaults,
  Configurator,
} from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";

@Component({ components: { BModalWrapper, CameraSettingsView } })
export default class CategoryOptionSettings extends Vue {
  @Prop() debug!: Debug;
  @Prop() value!: OptionDefaults;
  @Ref() modal!: BModalWrapper;
  @Prop() configurator!: Configurator;
  private selectedOptionDataOptions: OptionDefaults = EmptyOptionDefaults();

  public show() {
    this.selectedOptionDataOptions = {
      ...EmptyOptionDefaults(),
      ...this.value,
    };
    this.modal.show();
  }

  public save() {
    this.$emit("input", this.selectedOptionDataOptions);
    this.modal.hide();
  }

  public enforce(setting: string) {
    this.$emit(
      "enforceCartSettings",
      setting,
      (this.selectedOptionDataOptions.cartSettings as Record<string, any>)[
        setting
      ]
    );
  }
  public enforceCamera() {
    this.$emit(
      "enforceCameraSettings",
      this.selectedOptionDataOptions.cameraSettings
    );
  }
}
