













import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";
import {
  Requests,
  Employee,
  Debug,
  EmptyEmployee,
} from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";

@Component({ components: { BModalWrapper } })
export default class EditEmployeeView extends Vue {
  @Prop() debug!: Debug;
  @Ref() modal!: BModalWrapper;
  private employee: Employee = EmptyEmployee();
  private status = "";

  private async getData(id: number) {
    try {
      const [data, success] = await Requests.get("employee", id);
      if (success) {
        this.employee = data as Employee;
      } else {
        this.status = data as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }

  public show(id: number) {
    if (id != 0) {
      this.getData(id);
    } else {
      this.employee = EmptyEmployee();
    }
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  private cancel() {
    this.hide();
  }

  private async save() {
    this.status = "";
    try {
      const [data, success] = await (this.employee!.id == 0
        ? Requests.create("employee", this.employee)
        : Requests.update("employee", this.employee));

      if (success) {
        this.status = "Data saved!";
        this.$emit("created");
        if (this.employee!.id == 0) this.employee!.id = data as number;
      } else {
        this.status = ("Failed to save data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }
}
