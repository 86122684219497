import { defineStore } from "pinia";
import { useCategorySetStore } from "./CategorySetStore";
import { useSetCategoryStore } from "./SetCategoryStore";
import { CategoryOption } from "./StoreTypes";

interface StoreState {
  categoryOptions: Map<string, CategoryOption>;
}

export const useCategoryOptionStore = defineStore({
  id: "CategoryOptionStore",
  state: (): StoreState => ({
    categoryOptions: new Map(),
  }),
  getters: {
    getOption(state) {
      return (catOptInternalName: string) => {
        return state.categoryOptions.get(catOptInternalName);
      };
    },
    getOptions() {
      return (categoryInternalName: string) => {
        const options: CategoryOption[] = [];
        this.categoryOptions.forEach((option: any) => {
          if (option.categoryId === categoryInternalName) options.push(option);
        });

        return options;
      };
    },
    getAllOptions(state) {
      return Array.from(state.categoryOptions.values());
    },
  },
  actions: {
    addCategoryOption(categoryOption: CategoryOption) {
      this.categoryOptions.set(
        `${categoryOption.categoryId}::${categoryOption.optionInternalName}`,
        categoryOption
      );
    },
    addCategoryOptions(categoryOptions: CategoryOption[]) {
      categoryOptions.forEach(categoryOption => {
        this.addCategoryOption(categoryOption);
      });
    },
    updateOptionVisibility(catOptInternalName: string, visible: boolean) {
      const categoryOption = this.categoryOptions.get(catOptInternalName);

      if (!categoryOption) return undefined;

      categoryOption.isVisible = visible;

      const categorySetStore = useCategorySetStore();
      const setCategoryStore = useSetCategoryStore();

      categoryOption.needThisOptionNotVisibleToShow.categorySets.forEach(
        (csId: any) => {
          const set = categorySetStore.getSet(csId);

          if (!set) return undefined;

          const setIsVisible = set.isVisible && !visible;

          return categorySetStore.updateCategorySetVisibility(
            csId,
            setIsVisible
          );
        }
      );
      categoryOption.needThisOptionNotVisibleToShow.setCategories.forEach(
        (scInternalName: any) => {
          const category = setCategoryStore.getSetCategory(scInternalName);

          if (!category) return undefined;

          const categoryIsVisible = category.isVisible && !visible;

          return setCategoryStore.updateSetCategoryVisibility(
            scInternalName,
            categoryIsVisible
          );
        }
      );
      categoryOption.needThisOptionNotVisibleToShow.categoryOptions.forEach(
        (coInternalName: any) => {
          const option = this.getOption(coInternalName);

          if (!option) return undefined;

          const optionIsVisible = option.isVisible && !visible;

          return this.updateOptionVisibility(coInternalName, optionIsVisible);
        }
      );

      categoryOption.needThisOptionVisibleToShow.categorySets.forEach(
        (csId: any) => {
          const set = categorySetStore.getSet(csId);

          if (!set) return undefined;

          const setIsVisible = set.isVisible && !visible;

          return categorySetStore.updateCategorySetVisibility(
            csId,
            setIsVisible
          );
        }
      );
      categoryOption.needThisOptionVisibleToShow.setCategories.forEach(
        (scInternalName: any) => {
          const category = setCategoryStore.getSetCategory(scInternalName);

          if (!category) return undefined;

          const categoryIsVisible = category.isVisible && !visible;

          return setCategoryStore.updateSetCategoryVisibility(
            scInternalName,
            categoryIsVisible
          );
        }
      );
      categoryOption.needThisOptionVisibleToShow.categoryOptions.forEach(
        (coInternalName: any) => {
          const option = this.getOption(coInternalName);

          if (!option) return undefined;

          const optionIsVisible = option.isVisible && !visible;

          return this.updateOptionVisibility(coInternalName, optionIsVisible);
        }
      );
    },
    updateOptionSelected(catOptInternalName: string, selected: boolean) {
      const categoryOption = this.categoryOptions.get(catOptInternalName);

      if (!categoryOption) return undefined;

      categoryOption.isSelected = selected;

      const categorySetStore = useCategorySetStore();
      const setCategoryStore = useSetCategoryStore();

      categoryOption.needThisOptionNotSelectedToShow.categorySets.forEach(
        (csId: any) => {
          const set = categorySetStore.getSet(csId);

          if (!set) return undefined;

          const setIsVisible = set.isVisible && !selected;

          return categorySetStore.updateCategorySetVisibility(
            csId,
            setIsVisible
          );
        }
      );
      categoryOption.needThisOptionNotSelectedToShow.setCategories.forEach(
        (scInternalName: any) => {
          const category = setCategoryStore.getSetCategory(scInternalName);

          if (!category) return undefined;

          const categoryIsVisible = category.isVisible && !selected;

          return setCategoryStore.updateSetCategoryVisibility(
            scInternalName,
            categoryIsVisible
          );
        }
      );
      categoryOption.needThisOptionNotSelectedToShow.categoryOptions.forEach(
        (coInternalName: any) => {
          const option = this.getOption(coInternalName);

          if (!option) return undefined;

          const optionIsVisible = option.isVisible && !selected;

          return this.updateOptionVisibility(coInternalName, optionIsVisible);
        }
      );

      categoryOption.needThisOptionSelectedToShow.categorySets.forEach(
        (csId: any) => {
          const set = categorySetStore.getSet(csId);

          if (!set) return undefined;

          const setIsVisible = set.isVisible && !selected;

          return categorySetStore.updateCategorySetVisibility(
            csId,
            setIsVisible
          );
        }
      );
      categoryOption.needThisOptionSelectedToShow.setCategories.forEach(
        (scInternalName: any) => {
          const category = setCategoryStore.getSetCategory(scInternalName);

          if (!category) return undefined;

          const categoryIsVisible = category.isVisible && !selected;

          return setCategoryStore.updateSetCategoryVisibility(
            scInternalName,
            categoryIsVisible
          );
        }
      );
      categoryOption.needThisOptionSelectedToShow.categoryOptions.forEach(
        (coInternalName: any) => {
          const option = this.getOption(coInternalName);

          if (!option) return undefined;

          const optionIsVisible = option.isVisible && !selected;

          return this.updateOptionVisibility(coInternalName, optionIsVisible);
        }
      );
    },
    updateOptionEnabled(catOptInternalName: string, enabled: boolean) {
      const categoryOption = this.categoryOptions.get(catOptInternalName);

      if (!categoryOption) return undefined;

      categoryOption.isEnabled = enabled;

      const categorySetStore = useCategorySetStore();
      const setCategoryStore = useSetCategoryStore();

      categoryOption.needThisOptionNotEnabledToShow.categorySets.forEach(
        (csId: any) => {
          const set = categorySetStore.getSet(csId);

          if (!set) return undefined;

          const setIsVisible = set.isVisible && !enabled;

          return categorySetStore.updateCategorySetVisibility(
            csId,
            setIsVisible
          );
        }
      );
      categoryOption.needThisOptionNotEnabledToShow.setCategories.forEach(
        (scInternalName: any) => {
          const category = setCategoryStore.getSetCategory(scInternalName);

          if (!category) return undefined;

          const categoryIsVisible = category.isVisible && !enabled;

          return setCategoryStore.updateSetCategoryVisibility(
            scInternalName,
            categoryIsVisible
          );
        }
      );
      categoryOption.needThisOptionNotEnabledToShow.categoryOptions.forEach(
        (coInternalName: any) => {
          const option = this.getOption(coInternalName);

          if (!option) return undefined;

          const optionIsVisible = option.isVisible && !enabled;

          return this.updateOptionVisibility(coInternalName, optionIsVisible);
        }
      );

      categoryOption.needThisOptionEnabledToShow.categorySets.forEach(
        (csId: any) => {
          const set = categorySetStore.getSet(csId);

          if (!set) return undefined;

          const setIsVisible = set.isVisible && !enabled;

          return categorySetStore.updateCategorySetVisibility(
            csId,
            setIsVisible
          );
        }
      );
      categoryOption.needThisOptionEnabledToShow.setCategories.forEach(
        (scInternalName: any) => {
          const category = setCategoryStore.getSetCategory(scInternalName);

          if (!category) return undefined;

          const categoryIsVisible = category.isVisible && !enabled;

          return setCategoryStore.updateSetCategoryVisibility(
            scInternalName,
            categoryIsVisible
          );
        }
      );
      categoryOption.needThisOptionEnabledToShow.categoryOptions.forEach(
        (coInternalName: any) => {
          const option = this.getOption(coInternalName);

          if (!option) return undefined;

          const optionIsVisible = option.isVisible && !enabled;

          return this.updateOptionVisibility(coInternalName, optionIsVisible);
        }
      );
    },
    updateOptionCssClasses(catOptInternalName: string, classes: string[]) {
      const option = this.categoryOptions.get(catOptInternalName);

      if (!option) return undefined;

      if (!option.classes) return (option.classes = classes);

      return (option.classes = [...option.classes, ...classes]);
    },
    updateOptionCssStyles(catOptInternalName: string, styles: string) {
      const option = this.categoryOptions.get(catOptInternalName);

      if (!option) return undefined;

      if (!option.styles) return (option.styles = styles);

      return (option.styles = option.styles.concat(styles));
    },
  },
});

export default useCategoryOptionStore;
