





























import { Component, Prop, Vue, Ref, PropSync } from "vue-property-decorator";
import {
  Debug,
  ConfiguratorState,
  ConfiguratorStateManager,
  BakedConfig,
  Config,
  Category,
SetContainer,
CategorySet,
} from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";

@Component({ components: { BModalWrapper } })
export default class ConfigDefaults extends Vue {
  @Prop() debug!: Debug;
  @Prop() value!: ConfiguratorState;
  @Prop() baked!: BakedConfig;
  @Prop() config!: Config;
  @Prop() categories!: Category[];
  @Prop() containers!: SetContainer[];
  @Prop() sets!: CategorySet[];
  @Ref() modal!: BModalWrapper;
  private internalState: ConfiguratorState = {};
  private configuratorState: ConfiguratorStateManager = new ConfiguratorStateManager(
    this.debug,
    null,
    null
  );
  private get containerKeys() {
    return Object.keys(this.internalState).filter(k => k.includes("#"));
  }
  private get categoryKeys() {
    return Object.keys(this.internalState).filter(
      k => !k.includes("#") && !k.includes("::")
    );
  }
  private get optionKeys() {
    return Object.keys(this.internalState).filter(
      k => !k.includes("#") && k.includes("::")
    );
  }
  private get setNames() {
    return this.sets.map(s => s.internalName)
  }
  public generateStates(categories: Category[], containers: SetContainer[], initialState: any) {
    const state:any = {}
    for (const container of containers) {
      // only bring in if its in the blank state
      state["#" + container.internalName] = initialState["#" + container.internalName] ? initialState["#" + container.internalName] : {
        name: container.internalName,
        enabled: true,  
        selected: false,  
        currentSet: [...container.sets].length > 0 ? [...container.sets][0] : "" ,
     } 
    }
    for (const cat of categories) {
      // only bring in if its in the blank state
      state[cat.internalName] = initialState[cat.internalName] ? initialState[cat.internalName] : {
        name: cat.displayName,
          enabled: true,
    }
      for (const opt of cat.options) {
        state[cat.internalName + "::" + opt.internalName] = initialState[cat.internalName + "::" + opt.internalName] ? initialState[cat.internalName + "::" + opt.internalName] : {
          name: opt.displayName,
          enabled: true,
          selected: false,
          value: "",
     }
      }
    }
    return state
  }
  public show() {
    this.internalState = this.generateStates(this.categories, this.containers, (this.baked as any).config.initialState)
    this.modal.show();
  }

  public save() {
    this.$emit("input", this.internalState);
    this.modal.hide();
  }
}
