import { CSS } from "@monowarestudios/js-util";

export const encodeCSS = (css: CSS): string => {
  return Object.entries(css).reduce(
    (prevCss: string, [selector, content]: [string, string]) => {
      return prevCss + `${selector}{\n${content}\n}`;
    },
    ""
  );
};

export const parseCSS = (cssString: string): CSS => {
  let currStartPos = 0;
  const currCss: { [selector: string]: string } = {};

  while (currStartPos < cssString.length) {
    const startBracket = cssString.indexOf("{", currStartPos);
    const selector = cssString.substring(currStartPos, startBracket).trim();
    const endBracket = getMatchingBracket(startBracket, cssString);

    let content;

    if (endBracket - 1 < startBracket + 1) {
      content = "";
    } else {
      content = cssString.substring(startBracket + 1, endBracket - 1).trim();
    }

    //checks for empty content ie if all characters are spaces, newlines or tabs
    if (
      content.split("").every(currChar => {
        return [" ", "\n", "\t"].includes(currChar);
      })
    ) {
      content = "";
    }

    currCss[selector] = content;

    currStartPos = endBracket + 1;
  }

  return currCss;
};

const getMatchingBracket = (startingBracketPos: number, str: string) => {
  const stack = ["{"];
  let lastClose = startingBracketPos;

  let currStart = startingBracketPos + 1;

  while (stack.length > 0) {
    const nextOpen = str.indexOf("{", currStart);
    const nextClose = str.indexOf("}", currStart);

    if (nextOpen < nextClose && nextOpen !== -1) {
      stack.push("{");
      currStart = nextOpen + 1;
    } else {
      stack.pop();
      lastClose = nextClose;
      currStart = nextClose + 1;
    }
  }

  return lastClose;
};
