








import { StandalonePrintSetting } from '@monowarestudios/js-util';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class StandalonePrintEditor extends Vue {
  @Prop() value!: StandalonePrintSetting;
  selected: StandalonePrintSetting = {
    internalName: ''
  }

  save() {
    this.$emit('input', this.selected)
  }

  @Watch("value")
  private onChange() {
    this.selected = JSON.parse(JSON.stringify(this.value))
  }

  private mounted() {
    this.onChange()
  }
}
