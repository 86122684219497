






import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";
import {Requests, Category, Color, Texture} from "@monowarestudios/js-util"

@Component
export default class SelectDeployable extends Vue {
  @Prop() value!: number; // the deployment
  @Prop() kind!: string;
  @Prop() propClientID!: number;
  private options: any[] = []
  private selectedOption: any = {}
  private status = ""

  private get name() {
    return this.kind == "category" ? "templateName" : "name"
  }

  @Watch("value")
  private onValueChange() {
    this.selectedOption = this.value ? this.options.find((o: any) : boolean => o.id == this.value) : {}
  }

  @Watch("kind")
  private async onKindChange(){
    this.selectedOption = {}
    await this.loadOptions()
    this.selectedOption =  this.value ?  this.options.find((o: any) : boolean => o.id == this.value) : {}
  }

  private async mounted() {
    await this.loadOptions() 
    this.onValueChange()
  }

  @Watch("selectedOption.id")
  private onUpdate(newVal: number) {
    if(newVal){
      this.$emit("input", newVal)
    }
  }

  private async loadOptions() {
    try {
      const [data, success] = await Requests.listScoped(this.kind, this.propClientID);
      if (success) {
        this.options = data as any[];
      } else {
        this.status = data as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }

} 
