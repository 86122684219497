import Vue from "vue";
import Router from "vue-router";
import DashboardComponent from "./views/DashboardComponent.vue";
import BrowseComponent from "./views/BrowseComponent.vue";
import Editor from "./views/Editor.vue";
import ProductManager from "./views/ProductManager.vue";
import Cookies from "js-cookie";

Vue.use(Router);

function checkLoginGuard(to: any, from: any, next: any) {
  Cookies.get("mwtoken") ? next() : next(false);
}

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: DashboardComponent,
    },
    {
      path: "/browse/:type",
      name: "browse",
      component: BrowseComponent,
      beforeEnter: checkLoginGuard,
    },
    {
      path: "/productmanager/:client/:id",
      name: "productmanager",
      component: ProductManager,
      beforeEnter: checkLoginGuard,
    },
    {
      path: "/edit/:type/:id",
      name: "editor",
      component: Editor,
      beforeEnter: checkLoginGuard,
    },
  ],
});
