import { render, staticRenderFns } from "./StandaloneCartSummarySettingEditor.vue?vue&type=template&id=33b4aec8&scoped=true&lang=pug&"
import script from "./StandaloneCartSummarySettingEditor.vue?vue&type=script&lang=ts&"
export * from "./StandaloneCartSummarySettingEditor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b4aec8",
  null
  
)

export default component.exports