import { defineStore } from "pinia";
import {
  CssSnippet,
  Requests,
} from "@monowarestudios/js-util";

interface StoreState {
  snippets: Map<number, CssSnippet>;
  loading: boolean;
}

export const useCssSnippetStore = defineStore({
  id: "CssSnippetDeploymentStore",
  state: (): StoreState => ({
    snippets: new Map(),
    loading: false,
  }),
  getters: {
    getSnippet: state => {
      return (snippetId: number) => state.snippets.get(snippetId);
    },
    getAllSnippets: state => Array.from(state.snippets.entries()),
    getAllClientCssSnippets: state => {
      return (clientID: number) => {
        const snips = Array.from(state.snippets.values());

        return snips.filter(
          (snip: any) => snip.clientId === clientID
        );
      };
    },
    getDefaultCssSnippets: state => {
      const monowareId = 1;

      return Array.from(state.snippets.values()).filter(
        (snippet: any) => snippet.clientId === monowareId
      );
    },
  },
  actions: {
    async loadSnippets(clientId: number) {
      this.loading = true;


      const snippetsRequest = Requests.list("snippet");


      const [snippets, s_success] = await snippetsRequest
      if (!s_success) return new Error("could not load snippets");

      this.addSnippets(snippets as CssSnippet[]);

      this.loading = false;
    },
    addSnippet(snippet: CssSnippet) {
      this.$state.snippets.set(snippet.id, snippet);
    },
    addSnippets(snippets: CssSnippet[]) {
      snippets.forEach(snippet => this.addSnippet(snippet));
    },
    async saveSnippet(snippet: CssSnippet) {
      const [s, success] = await Requests.update("snippet", snippet);

      if (!success) {
        return new Error("could not update snippet");
      }

      this.addSnippet(s);

      return s;
    },
    async saveNewSnippet(newSnippet: CssSnippet) {
      const [s, success] = await Requests.create("snippet", newSnippet);

      if (!success) {
        return new Error("could not save new snippet");
      }

      this.addSnippet(s);

      return s;
    },
  },
});
