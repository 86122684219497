

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Config, StandaloneCartSummarySetting } from '@monowarestudios/js-util';

@Component
export default class StandaloneCartSummarySettingEditor extends Vue {
  @Prop() value!: StandaloneCartSummarySetting[]
  @Prop() config!: Config
  private standaloneCartSummaries: StandaloneCartSummarySetting[] = []
  private selected = 0

  addStandaloneCartSummary() {
    this.standaloneCartSummaries.push({
      internalName: '',
      showTotal: false,
      showGroupedPrice: false,
      showGroups: false,
    })
    this.selected = this.standaloneCartSummaries.length - 1
  }
  selectStandaloneCartSummary(index: number) {
    this.selected = index
  }
  deleteStandaloneCartSummary(index: number) {
    this.standaloneCartSummaries.splice(index, 1)
    this.selected = this.standaloneCartSummaries.length - 1
    this.$forceUpdate()
  }
  copyStandaloneCartSummary(modal: StandaloneCartSummarySetting) {
    this.standaloneCartSummaries.push(
      JSON.parse(
        JSON.stringify(
          modal
        )
      )
    )

    this.selected = this.standaloneCartSummaries.length - 1
  }

  save() {
    this.$emit('input', this.standaloneCartSummaries)
  }

  @Watch("value")
  private onValue() {
    this.standaloneCartSummaries = JSON.parse(JSON.stringify(this.value || []));
    this.selected = this.standaloneCartSummaries.length - 1;
  }
  private mounted() {
    this.onValue();
  }
}
