import { render, staticRenderFns } from "./OptionOverrideDetailsView.vue?vue&type=template&id=2b7a1284&scoped=true&lang=pug&"
import script from "./OptionOverrideDetailsView.vue?vue&type=script&lang=ts&"
export * from "./OptionOverrideDetailsView.vue?vue&type=script&lang=ts&"
import style0 from "./OptionOverrideDetailsView.vue?vue&type=style&index=0&id=2b7a1284&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b7a1284",
  null
  
)

export default component.exports