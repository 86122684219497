













import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";
import { Requests, Color, Debug, EmptyColor } from "@monowarestudios/js-util";
import { BModal } from "bootstrap-vue";
import BModalWrapper from "@/components/BModalWrapper.vue";

@Component({ components: { BModalWrapper } })
export default class CreateColorsView extends Vue {
  @Prop() private propClientID!: number;
  @Prop() debug!: Debug;
  @Ref() modal!: BModal;
  private status = "";
  private colors: Color[] = [];

  private add() {
    this.colors.push({ ...EmptyColor(), clientID: this.propClientID });
  }

  public show() {
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  private cancel() {
    this.hide();
  }

  private onColorChange(colorString: string, index: number) {
    if (colorString[0] === "#" && colorString.length === 9) {
      this.colors[index].color = this.parseHex(colorString);
      return;
    }

    this.colors[index].color = colorString;
  }

  private parseHex(code: string) {
    const red = parseInt(code.slice(1, 3), 16) / 255;
    const green = parseInt(code.slice(3, 5), 16) / 255;
    const blue = parseInt(code.slice(5, 7), 16) / 255;
    const alpha = parseInt(code.slice(7, 9), 16) / 255;

    return `${red},${green},${blue},${alpha}`;
  }

  private previewColor(color: Color) {
    const split = color.color.split(",").map(parseFloat);
    const alpha = split.length == 4 ? split.pop() : 1;
    const fixed = split.map((n: number) => n * 255);
    return [...fixed, alpha].join(",");
  }

  private async save() {
    this.status = "";
    try {
      const [data, success] = await Requests.create("color", this.colors);
      if (success) {
        this.status = "Successfully created";
        this.colors = [];
        this.$emit("created");
      } else {
        this.status = ("Failed to save data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }
}
