

























































































































































































import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";

import {
  StandaloneContentSetting,
  StandaloneCurrencySelectorSetting, StandaloneReference,
  StandaloneFormSetting, Config, SetCategory, Category, SetContainer, StandaloneFinanceSetting
} from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";
import DependenciesEditor from "./DependenciesEditor.vue";
import { useConfigStore } from "../../../../stores/ConfigStore";
import { useCategorySetStore } from "../../../../stores/CategorySetStore";
import { useSetCategoryStore } from "../../../../stores/SetCategoryStore";
import { useCategoryOptionStore } from "../../../../stores/CategoryOptionStore";
import { CategorySet, JSONSet } from "../../../../stores/StoreTypes";

@Component({
  components: {
    BModalWrapper,
    DependenciesEditor,
  },
})
// TODO: add currency, summary here
export default class AddMobileContainer extends Vue {
  @Prop() private value!: Config;
  @Prop() private show!: boolean;
  @Prop() private possibleCategories!: Category[];
  @Prop() private possibleContainers!: SetContainer[];
  @Prop() private possibleStandaloneTexts!: StandaloneContentSetting[];
  @Prop() private possibleStandaloneModals!: StandaloneReference[];
  @Prop() private possibleStandaloneImages!: StandaloneContentSetting[];
  @Prop() private possibleCartButtons!: StandaloneContentSetting[];
  @Prop() private possibleForms!: StandaloneFormSetting[];
  // TODO: saving and usage
  @Prop() private possibleCartSummarys!: StandaloneContentSetting[];
  @Prop() private possibleCurrencySelectors!: StandaloneCurrencySelectorSetting[];
  @Prop() private possibleFinanceComponents!: StandaloneFinanceSetting[];
  @Prop() private possiblePrintComponents!: StandaloneFinanceSetting[];
  @Ref() modalRef!: BModalWrapper;
  private configStore = useConfigStore();
  private currentConfigId = 0;

  private currSetCategoryIndex = 0;

  private categorySetStore = useCategorySetStore();
  private setCategoryStore = useSetCategoryStore();
  private categoryOptionStore = useCategoryOptionStore();
  private currentEditCategory = "";

  private currentCategorySetInternalName = "";
  // current classes
  private newCurrClass = "";

  // Adding set stuff
  private isAddingSet = false;
  private newSet: CategorySet = {
    internalName: "",
    categories: new JSONSet<string>(),
    currentCategories: new JSONSet<string>(),
    containers: new JSONSet<string>(),
    standaloneTexts: new JSONSet<string>(),
    standaloneModals: new JSONSet<string>(),
    standaloneImages: new JSONSet<string>(),
    standaloneForms: new JSONSet<string>(),
    standaloneCartButtons: new JSONSet<string>(),
    standaloneCartSummary: new JSONSet<string>(),
    financeComponents: new JSONSet<string>(),
    standaloneCurrencySelector: new JSONSet<string>(),
    standalonePrint: new JSONSet<string>(),
    isVisible: false,
    showCartIcon: false,
    showDataSheet: false,
    needThisCategorySetNotVisibleToShow: {
      categorySets: [],
      setCategories: [],
      categoryOptions: [],
    },
    needThisCategorySetVisibleToShow: {
      categorySets: [],
      setCategories: [],
      categoryOptions: [],
    },
    classes: [],
    styles: "",
    containerRef: null,
  };
  // adding classes stuff
  private isAddingClass = false;
  private newClass = "";

  private get categorySets() {
    return this.configStore?.getCategorySets(this.currentConfigId) || [];
  }

  private get selectedSetCategories() {
    return (
      this.setCategoryStore
        .getSetCategories(this.currentCategorySetInternalName)
        ?.map((setCategory: SetCategory) => setCategory.categoryInternalName) ||
      []
    );
  }

  // Category stuff
  /*
    const possibleCategories = computed(() =>
      configStore.getPossibleCategories(currentConfigId)
    );
    const possibleCategoryNames = computed(() =>
      possibleCategories.map(
        (pc: SetCategory | undefined) => pc?.categoryInternalName
      )
    ); */
  private mounted() {
    if (this.value.id !== 0) {
      this.currentConfigId = this.value.id;
      this.configStore.addConfig(this.value);
    }
  }

  private onSave() {
    const config = this.configStore.getConfig(this.value.id);

    this.$emit("input", config);
  }

  private changeSetCategoryIndex(e: any) {
    if (e.moved) {
      if (e.moved.oldIndex === this.currSetCategoryIndex) {
        this.currSetCategoryIndex = e.moved.newIndex;
      }
    }
  }

  private addCurrClass() {
    if (!this.currentCategorySet) return;
    if (!this.currentCategorySet.classes)
      this.currentCategorySet.classes = [this.newCurrClass];

    this.currentCategorySet.classes.push(this.newCurrClass);

    this.newCurrClass = "";
  }
  private copyCurrentSet() {
    const currSet = this.categorySetStore.getCurrentSet;

    if (!currSet) return;

    this.newSet = { ...currSet };
  }

  private addClass() {
    if (!this.newSet.classes) this.newSet.classes = [this.newClass];

    this.newSet.classes.push(this.newClass);

    this.newClass = "";
  }

  private addSet(e: Event) {
    this.configStore.addCategorySet(this.currentConfigId, this.newSet);
    this.categorySetStore.addSet(this.newSet);

    this.newSet = {
      internalName: "",
      categories: new JSONSet<string>(),
      currentCategories: new JSONSet<string>(),
      containers: new JSONSet<string>(),
      standaloneTexts: new JSONSet<string>(),
      standaloneImages: new JSONSet<string>(),
      standaloneForms: new JSONSet<string>(),
      standaloneModals: new JSONSet<string>(),
      financeComponents: new JSONSet<string>(),
      standaloneCartButtons: new JSONSet<string>(),
      standaloneCartSummary: new JSONSet<string>(),
      standaloneCurrencySelector: new JSONSet<string>(),
      standalonePrint: new JSONSet<string>(),
      isVisible: false,
      showCartIcon: false,
      showDataSheet: false,
      needThisCategorySetNotVisibleToShow: {
        categorySets: [],
        setCategories: [],
        categoryOptions: [],
      },
      needThisCategorySetVisibleToShow: {
        categorySets: [],
        setCategories: [],
        categoryOptions: [],
      },
      classes: [],
      styles: "",
      containerRef: null,
    };

    this.isAddingSet = false;
  }

  private removeSet() {
    this.categorySetStore.removeSet(this.currentCategorySetInternalName);
  }

  private get possibleClasses() {
    if (!this.newSet.classes) return [];

    return [...this.newSet.classes];
  }
  private get possibleCurrClasses() {
    if (!this.currentCategorySet) return [];
    if (!this.currentCategorySet.classes) return [];

    return [...this.currentCategorySet.classes];
  }

  private get currentCategorySet() {
    return this.categorySetStore.getSet(this.currentCategorySetInternalName);
  }
  private get categorySetNames() {
    return (
      this.configStore
        .getCategorySets(this.currentConfigId)
        .map((set: any) => set.internalName) || []
    );
  }

  private get possibleContainerNames() {
    return this.possibleContainers.map(c => c.internalName)
  }

  private get possibleCategoryNames() {
    return this.possibleCategories.map((pc: Category) => pc?.internalName);
  }

  private get possibleStandaloneTextNames() {
    return this.possibleStandaloneTexts.map(st => st.internalName)
  }
  private get possibleStandaloneModalNames() {
    return this.possibleStandaloneModals.map(st => st.internalName)
  }
  private get possibleStandaloneImageNames() {
    return this.possibleStandaloneImages.map(si => si.internalName)
  }
  private get possibleStandaloneFormNames() {
    return this.possibleForms.map(si => si.internalName)
  }
  private get possibleStandaloneCartButtonNames() {
    return this.possibleCartButtons.map(si => si.internalName)
  }
  private get possibleStandaloneCartSummaryNames() {
    return this.possibleCartSummarys.map(si => si.internalName)
  }
  private get possibleStandaloneCurrencySelectorNames() {
    return this.possibleCurrencySelectors.map(si => si.internalName)
  }
  private get possibleFinanceComponentNames() {
    return this.possibleFinanceComponents.map(fc => fc.internalName)
  }
  private get possibleStandalonePrintNames() {
    return this.possiblePrintComponents.map(fc => fc.internalName)
  }

  private get setCategoryNames() {
    return (
      this.configStore
        .getSetCategories(this.currentConfigId)
        .map((category: any) => category.category.internalName) || []
    );
  }

  // Option stuff
  private get categoryOptions() {
    const category = this.setCategoryStore.getSetCategory(
      this.currentEditCategory
    );

    if (!category) return [];

    const optionInternalNames = Array.from(category.options);

    return (
      optionInternalNames
        .map(internalName =>
          this.categoryOptionStore.getOption(internalName as string)
        )
        .filter(option => option !== undefined) || []
    );
  }
  private get categoryOptionNames() {
    const options = this.categoryOptions;

    return (
      options
        .map(option => option?.optionInternalName)
        .filter(option => option !== undefined) || []
    );
  }
  /* private get possibleOptions() {
      return possibleCategories.value.flatMap((pc: SetCategory | undefined) => {
        return (
          categoryOptionStore.getOptions(pc?.categoryInternalName || "") || []
        );
      };
    }; */
  private get possibleOptions() {
    return this.possibleCategories.flatMap((pc: Category) => {
      return pc.options;
    });
  }

  @Watch("value")
  private watchValue(value: any) {
    // check if empty config or the same config
    if (value.id !== 0 || value.id === this.value.id) {
      this.currentConfigId = value.id;

      if (!this.configStore.getConfig(value.id)) return;

      this.configStore.addConfig(value);
    }
  }

  @Watch("show")
  private watchShow(val: boolean) {
    val ? this.modalRef!.show() : null;
  }
}
