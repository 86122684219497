



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import StandalonePrintEditor from './StandalonePrintEditor.vue';
import { StandalonePrintSetting } from '@monowarestudios/js-util';

@Component({
  components: {
    StandalonePrintEditor
  }
})
export default class StandalonePrintSettingEditor extends Vue {
  @Prop() value!: StandalonePrintSetting[]
  @Prop() availableStandalonePrintSettings!: StandalonePrintSetting[]

  standalonePrintSettings: StandalonePrintSetting[] = []
  selected = 0
  standaloneToAddName = ''
  standaloneToAdd: StandalonePrintSetting = {
    internalName: ''
  }

  get availableOptions() {
    return this.availableStandalonePrintSettingNames.filter(
      asps => !this.standalonePrintSettings.map(sps => sps.internalName).includes(asps)
    )
  }

  get availableStandalonePrintSettingNames() {
    return this.availableStandalonePrintSettings.map(asps => asps.internalName)
  }

  addStandalonePrintSetting() {
    if (
      (this.availableStandalonePrintSettings && this.standaloneToAdd.internalName)
      || !this.availableStandalonePrintSettings
    ) {
      this.standalonePrintSettings.push(
        JSON.parse(JSON.stringify(this.standaloneToAdd))
      )

      this.selected = this.standalonePrintSettings.length - 1
      this.standaloneToAdd = {
        internalName: ''
      }
    }
  }
  selectStandalonePrintSetting(index: number) {
    this.selected = index
  }
  deleteStandalonePrintSetting(index: number) {
    this.standalonePrintSettings.splice(index, 1)
    this.selected = this.standalonePrintSettings.length - 1
    this.$forceUpdate()
  }
  copyStandalonePrintSetting(standalone: StandalonePrintSetting) {
    this.standalonePrintSettings.push(JSON.parse(JSON.stringify(standalone)))
    this.selected = this.standalonePrintSettings.length - 1
  }

  save() {
    this.$emit("input", this.standalonePrintSettings)
  }

  @Watch("value")
  private onValue() {
    this.standalonePrintSettings = JSON.parse(JSON.stringify(this.value));
    this.selected = this.standalonePrintSettings.length - 1;
  }

  mounted() {
    this.onValue()
  }
}
