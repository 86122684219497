































import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import {
  Requests,
  Product,
  Category,
  Debug,
  Config,
} from "@monowarestudios/js-util";
import CategoryDetailsView from "./config_details_views/category/CategoryDetailsView.vue";
//import VariantGenerator from "./product_details_views/VariantGenerator";
import ProductVariantEditor from "./product_details_views/ProductVariantEditor.vue";
import ProductMetafieldEditor from "./product_details_views/ProductMetafieldEditor.vue";

@Component({
  components: {
    CategoryDetailsView,
    ProductVariantEditor,
    ProductMetafieldEditor,
  },
})
export default class ProductDetailsView extends Vue {
  @Prop() debug!: Debug;
  @Prop() config!: Config;
  @Prop() value!: Product; // master state from parent
  @Ref() variantEdit!: ProductVariantEditor;
  @Ref() metafieldEdit!: ProductMetafieldEditor;
  private status = "";
  private showCategoryDetails = false;

  private get product() {
    return this.value || {};
  }
  /*  // this will get its own standalone system
  private addCategory() {
    (this.$refs.categoryDetails as CategoryDetailsView).setDirect(null, -1);
    this.showCategoryDetails = true;
  }

  private editCategory(cat: Category, index: number) {
    (this.$refs.categoryDetails as CategoryDetailsView).setDirect(cat, index);
    this.showCategoryDetails = true;
  } */

  private categoryUpdated(newCat: Category, index: number) {
    index == this.product!.options.length || index == -1
      ? this.product!.options.push(newCat)
      : (this.product!.options[index] = newCat);
    this.$forceUpdate();
  }

  private deleteCategory(index: number) {
    this.product!.options.splice(index, 1);
  }

  //private copyCategory(cat: Category) {}
  // does not affect any configs
  /* private regenerateVariants() {
    this.product!.variants = VariantGenerator(this.product!.options);
  } */

  private async saveProduct() {
    this.status = "";
    try {
      const [data, success] = await (this.product!.id == 0
        ? Requests.create("product", this.product)
        : Requests.update("product", this.product));
      if (success) {
        this.status = "Data saved!";
        this.product.id = this.product.id || (data as any)["product"];
        this.$emit("input", this.product);
      } else {
        this.status = ("Failed to save data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }
  private hideCategoryDetails() {
    this.showCategoryDetails = false;
  }

  private hide() {
    this.$emit("hide");
  }

  private editVariants() {
    this.variantEdit.show();
  }
  private editMetafields() {
    this.metafieldEdit.show();
  }

  private async regenerateCategories() {
    const conf = await this.$bvModal.msgBoxConfirm(
      `WARNING: this should only be used when first creating a product. Running it again can BREAK ALL CONFIGS that depend on categories that are a part of this product. ARE YOU SURE?`,
      { title: "I sure hope you know what you are doing." }
    );
    if (conf) {
      const [data, success] = await Requests.generateCategoriesForProduct(
        this.product!,
        this.config.id
      );
      if (success) {
        this.$emit("generateCategories", data);
        this.status = "Generated! MAKE SURE TO SAVE THE CONFIG.";
      } else {
        this.status = ("Failed to generate: " + data) as string;
      }
    }
  }
}
