




























































































import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";
import {
  Category,
  Option,
  Debug,
  CatOptNames,
  Requests,
  RenderSettings,
  CategoryOverride,
  Configurator,
  CameraMovementSetting,
  StandaloneContentSetting,
} from "@monowarestudios/js-util";
import RenderSettingsView from "@/components/product_manager_views/config_details_views/RenderSettingsView.vue";
import CategoryOptionSettings from "@/components/product_manager_views/config_details_views/category/CategoryOptionSettings.vue";
import OptionDetailsView from "../option/OptionDetailsView.vue";
import OptionOverrideDetailsView from "../option/OptionOverrideDetailsView.vue";
import SelectDeployable from "@/components/SelectDeployable.vue";

@Component({
  components: {
    RenderSettingsView,
    CategoryOptionSettings,
    OptionDetailsView,
    SelectDeployable,
    OptionOverrideDetailsView,
  },
})
export default class CategoryDetailsView extends Vue {
  @Prop() debug!: Debug;
  @Prop() configId!: number;
  @Prop() existingCategories!: Category[];
  @Prop() availableStandaloneTexts!: StandaloneContentSetting[];
  @Prop() availableStandaloneImages!: StandaloneContentSetting[];
  @Prop() configurator!: Configurator;
  @Prop() clientID!: number;
  @Prop() override!: CategoryOverride;
  @Prop() annotations!: any[];
  @Prop() variants!: any[];
  @Prop() materialList!: Record<string, any>;
  @Prop() geometryList!: Record<string, any>;
  @Prop() geometryTree!: Record<string, any>;

  @Ref() catOptSettings!: CategoryOptionSettings;
  @Ref() catCSSSettings!: RenderSettingsView;
  @Ref() optionDetails!: OptionDetailsView;
  @Ref() remoteOptionOverridesDetails!: OptionOverrideDetailsView;

  private category: Category = new Category();
  private remoteCategory = 0; // template reference
  private status = "";
  private showOptionDetails = false;
  private showEditRemoteOptionOverrides = false;
  private isRemote = false;
  private canEditInternalName = false;
  private renderSettings: {
    renderSettings: RenderSettings;
    portraitRenderSettings: RenderSettings;
  } = {
    renderSettings: new RenderSettings({}),
    portraitRenderSettings: new RenderSettings({}),
  };

  @Watch("renderSettings")
  private updateRenderSettings(newRenderSettings: {
    renderSettings: RenderSettings;
    portraitRenderSettings: RenderSettings;
  }) {
    this.category.renderSettings = newRenderSettings.renderSettings;
    this.category.portraitRenderSettings =
      newRenderSettings.portraitRenderSettings;
  }

  @Watch("isRemote")
  private onRemoteSelect(newVal: boolean) {
    if (!newVal) this.remoteCategory = 0;
  }
  @Watch("override")
  private async onOverride() {
    if (this.override) {
      this.isRemote = Boolean(this.override.categoryID);
      this.canEditInternalName = !this.override.internalName;
      if (this.isRemote) {
        this.remoteCategory = this.override.categoryID;
      }
      this.category = {
        // TODO: should copy this if we turn off being remote as well and turning on should wipe current
        ...new Category(),
        ...this.override.category,
        internalName: this.override.internalName,
        displayName: this.override.displayName,
        isHidden: this.override.isHidden,
      };
      this.renderSettings = {
        renderSettings: this.category.renderSettings,
        portraitRenderSettings: this.category.portraitRenderSettings,
      };
    }
  }

  @Watch("renderSettings")
  private onRenderSettings() {
    this.category.renderSettings = this.renderSettings.renderSettings;
    this.category.portraitRenderSettings = this.renderSettings.portraitRenderSettings;
  }

  private mounted() {
    this.onOverride();
  }

  private get optionNames() {
    return this.category.options.map(opt => opt.internalName);
  }
  private enforceOptionDefault(setting: string, value: string) {
    this.category.options.map(
      (opt: Option) =>
        ((opt.cartSettings as { [index: string]: any })[setting] = value)
    );
  }
  private enforceCameraSettings(cameraSettings: CameraMovementSetting) {
    this.category.options.map(
      (opt: Option) =>
        (opt.apiSettings.cameraMovements = JSON.parse(
          JSON.stringify(cameraSettings)
        ))
    );
  }

  private hide() {
    this.$emit("hide");
    this.showOptionDetails = false;
  }

  private addOption() {
    this.optionDetails.set({
      ...new Option(),
      ...this.category.optionDefaults,
    });
    this.showOptionDetails = true;
  }

  private editOption(opt: Option) {
    this.optionDetails.set(opt);
    this.showOptionDetails = true;
  }

  private editRemoteOptionOverrides(opt: Option) {
    this.remoteOptionOverridesDetails.set(opt);
    this.showEditRemoteOptionOverrides = true;
  }

  private optionUpdated(option: Option) {
    const index = this.category.options.findIndex(
      (o: Option) => o.internalName == option.internalName
    );
    if (index == -1) this.category.options.push(option);
    else this.category.options[index] = option;
    this.$forceUpdate();
  }

  private deleteOption(index: number) {
    this.category.options.splice(index, 1);
    this.$forceUpdate();
  }

  private copyOption(opt: Option) {
    const dupe: Option = JSON.parse(JSON.stringify(opt));
    dupe.internalName = ""; // TODO: do for variant setuff too
    const originIndex = this.category.options.findIndex(o => o.internalName == opt.internalName)
    this.category.options = [...this.category.options.slice(0, originIndex), dupe, ...this.category.options.slice(originIndex)] as any
    (this.$refs.optionDetails as OptionDetailsView).set(dupe);
    this.showOptionDetails = true;
  }
  private async saveAsRemote() {
    this.status = "";
    try {
      const [data, success] = await Requests.create("category", {
        ...this.category,
        templateName: this.category.displayName,
        id: 0,
        clientID: this.clientID,
      });
      if (success) {
        this.status = "Template saved & automatically selected for use";
        this.isRemote = true;
        this.remoteCategory = data.id;
      } else {
        this.status = ("Failed to save data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }

  // not used for template editing
  private async save() {
    this.status = "";
    // if we are remote, remote id must not be zero
    if (this.isRemote && this.remoteCategory == 0) {
      this.status =
        "Please select a template or convert to local category via slider";
      return;
    }
    if (!this.category.internalName) {
      this.status = "Internal name required";
      return;
    }
    // make sure local categories have no category ID set!
    if (this.remoteCategory == 0) {
      this.category.id = 0;
    }
    this.$emit(
      // we are either saving remote cateogry or local category
      "save",
      {
        displayName: this.category.displayName, // always
        isHidden: this.category.isHidden, // always
        internalName: this.category.internalName, // always
        category: this.remoteCategory ? null : this.category, // changes remote/not remote
        categoryID: this.remoteCategory, // always
      }
    );
    this.canEditInternalName = false;
  }
}
