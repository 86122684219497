
























import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import { Requests, Client, Debug, EmptyClient } from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";

@Component({ components: { BModalWrapper } })
export default class EditClientView extends Vue {
  @Prop() debug!: Debug;
  @Ref() modal!: BModalWrapper;
  private status = "";
  private client: Client = EmptyClient();

  private async getData(id: number) {
    try {
      const [data, success] = await Requests.get("client", id);
      if (success) {
        this.client = data as Client;
      } else {
        this.status = data as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }

  public show(id: number) {
    if (id != 0) {
      this.getData(id);
    } else {
      this.client = EmptyClient();
    }
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  private cancel() {
    this.hide();
  }

  private async save() {
    this.status = "";
    try {
      const [data, success] = await (this.client!.id == 0
        ? Requests.create("client", this.client)
        : Requests.update("client", this.client));
      if (success) {
        this.status = "Data saved!";
        this.$emit("created");
        if (this.client!.id == 0) this.client!.id = data as number;
      } else {
        this.status = ("Failed to save data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }
}
