









































import BModalWrapper from "@/components/BModalWrapper.vue";
import useCategorySetStore from "@/stores/CategorySetStore";
import useConfigStore from "@/stores/ConfigStore";
import { useSetContainerStore } from "@/stores/SetContainerStore";
import {
  Config,
  JSONSet,
  SetContainer,
  CategorySet,
} from "@monowarestudios/js-util";
import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";

@Component({
  components: {
    BModalWrapper,
  },
})
export default class SetContainerEditor extends Vue {
  @Prop() private value!: Config;
  @Prop() private navs!: string[];
  @Prop() private show!: boolean;
  @Ref() modalRef!: BModalWrapper;

  private configStore = useConfigStore();
  private setStore = useCategorySetStore();
  private containerStore = useSetContainerStore();

  private currentConfigId = 0;
  private isAddingContainer = false;
  private newContainer: SetContainer = {
    internalName: "",
    sets: new JSONSet<string>(),
    isNav: false,
    rolloverNav: "",
    //disableNavConditions: [] // TODO: if any of this are true, we disable movement and show the appropiate validationmessages
  };

  private currSetIndex = 0;
  private newSets: string[] = [];
  private currentContainer = "";
  private currentContainerObj: SetContainer = {
    internalName: "",
    sets: new JSONSet<string>(),
    isNav: false,
    rolloverNav: "",
  };
  private mounted() {
    if (this.value.id !== 0) {
      this.currentConfigId = this.value.id;
      this.configStore.addConfig(this.value);
    }
  }

  private get sets() {
    return this.configStore
      .getCategorySets(this.value.id)
      .map((cs: CategorySet | undefined) => cs?.internalName) as string[];
  }
  private get containers() {
    return this.configStore.getContainers(this.currentConfigId);
  }

  private changeSetIndex(e: any) {
    if (e.moved) {
      if (e.moved.oldIndex === this.currSetIndex) {
        this.currSetIndex = e.moved.newIndex;
      }
    }
  }

  private addContainer() {
    this.newContainer.sets = new JSONSet<string>(this.newSets);

    this.containerStore.addContainer(this.newContainer);
    this.configStore.addContainer(this.value.id, this.newContainer);

    this.isAddingContainer = false;
    this.newContainer = {
      internalName: "",
      rolloverNav: "",
      isNav: false,
      sets: new JSONSet<string>(),
    };
    this.newSets = [];
  }

  @Watch("currentContainer")
  private watchCurrentContainer() {
    this.currentContainerObj = this.containerStore.getContainer(
      this.currentContainer!
    ) as any;
  }

  @Watch("value")
  private watchValue(value: any) {
    // check if empty config or the same config
    if (value.id !== 0 || value.id === this.value.id) {
      this.currentConfigId = value.id;

      if (!this.configStore.getConfig(value.id)) return;

      this.configStore.addConfig(value);
    }
  }
  @Watch("show")
  private watchShow(val: boolean) {
    val ? this.modalRef!.show() : null;
  }
  private removeContainer() {
    this.containerStore.removeContainer(this.currentContainer);
    this.configStore.removeContainer(
      this.currentConfigId,
      this.currentContainer
    );
  }

  private onSave() {
    const config = this.configStore.getConfig(this.value.id);

    this.$emit("input", config);
  }
}
