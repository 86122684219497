





















































import { Component, Prop, Vue, Ref, Watch } from "vue-property-decorator";
import BModalWrapper from "@/components/BModalWrapper.vue";
import {
  Debug,
  Category,
  Requests,
  Option,
  RenderSettings,
  CameraMovementSetting,
} from "@monowarestudios/js-util";
import RenderSettingsView from "@/components/product_manager_views/config_details_views/RenderSettingsView.vue";
import CategoryOptionSettings from "@/components/product_manager_views/config_details_views/category/CategoryOptionSettings.vue";
import OptionDetailsView from "@/components/product_manager_views/config_details_views/option/OptionDetailsView.vue";
import { runInThisContext } from "node:vm";

@Component({
  components: {
    BModalWrapper,
    RenderSettingsView,
    CategoryOptionSettings,
    OptionDetailsView,
  },
})
export default class EditCategoryTemplate extends Vue {
  @Prop() debug!: Debug;
  @Prop() propClientID!: number;

  @Ref() catOptSettings!: CategoryOptionSettings;
  @Ref() catCSSSettings!: RenderSettingsView;
  @Ref() optionDetails!: OptionDetailsView;
  @Ref() modal!: BModalWrapper;
  private category = new Category();
  private status = "";
  private showOptionDetails = false;

  private renderSettings: {
    renderSettings: RenderSettings;
    portraitRenderSettings: RenderSettings;
  } = {
    renderSettings: new RenderSettings({}),
    portraitRenderSettings: new RenderSettings({}),
  };
  @Watch("renderSettings")
  private updateRenderSettings(newRenderSettings: {
    renderSettings: RenderSettings;
    portraitRenderSettings: RenderSettings;
  }) {
    this.category.renderSettings = newRenderSettings.renderSettings;
    this.category.portraitRenderSettings =
      newRenderSettings.portraitRenderSettings;
  }

  public async show(id: number) {
    if (id) {
      const [data, success] = await Requests.get("category", id);
      if (success) {
        this.category = data as Category;
      }
    } else {
      this.category = { ...new Category(), clientID: this.propClientID };
    }
    this.renderSettings = {
      renderSettings: this.category.renderSettings,
      portraitRenderSettings: this.category.portraitRenderSettings,
    };
    this.modal.show();
  }

  private async onDuplicateTemplate() {
    this.status = "";
    try {
      const [data, success] = await Requests.create("category", { ...this.category, templateName: "RENAME ME - " + this.category.templateName, id: 0 });
      if (success) {
        this.status = "Template Duplicated!";
        this.$emit("created");
      } else {
        this.status = ("Failed to save data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }
  private async onSaveTemplate() {
    this.status = "";
    try {
      const [data, success] = await (this.category.id
        ? Requests.update("category", this.category)
        : Requests.create("category", { ...this.category, id: 0 }));
      if (success) {
        this.status = "Template saved!";
        this.category.id = this.category.id
          ? (data.id as number)
          : this.category.id;
        this.$emit("created");
      } else {
        this.status = ("Failed to save data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }

  private get optionNames() {
    return this.category.options.map(opt => opt.internalName);
  }
  private enforceOptionDefault(setting: string, value: string) {
    this.category.options.map(
      (opt: Option) =>
        ((opt.cartSettings as { [index: string]: any })[setting] = value)
    );
  }
  private enforceCameraSettings(cameraSettings: CameraMovementSetting) {
    this.category.options.map(
      (opt: Option) =>
        (opt.apiSettings.cameraMovements = JSON.parse(
          JSON.stringify(cameraSettings)
        ))
    );
  }

  private addOption() {
    this.optionDetails.set({
      ...new Option(),
      ...this.category.optionDefaults,
    });
    this.showOptionDetails = true;
  }

  private editOption(opt: Option, index: number) {
    this.optionDetails.set(opt);
    this.showOptionDetails = true;
  }

  private optionUpdated(option: Option) {
    const index = this.category.options.findIndex(
      (o: Option) => o.internalName == option.internalName
    );
    if (index == -1) this.category.options.push(option);
    else this.category.options[index] = option;
    this.$forceUpdate();
  }

  private deleteOption(index: number) {
    this.category.options.splice(index, 1);
    this.$forceUpdate();
  }

  private copyOption(opt: Option) {
    const dupe = JSON.parse(JSON.stringify(opt));
    dupe.internalName = "";
    this.category.options.push(dupe);
    (this.$refs.optionDetails as OptionDetailsView).set(dupe);
    this.showOptionDetails = true;
  }

  private async onDeleteTemplate() {
    this.status = "";
    try {
      const [data, success] = await Requests.delete(
        "category",
        this.category.id
      );
      if (success) {
        this.status = "Template delete";
        this.$emit("created");
      } else {
        this.status = ("Failed to save data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }
}
