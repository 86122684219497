
























import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import {
  Variant,
  EmptyVariant,
  Category,
  VariantAnnotation,
} from "@monowarestudios/js-util";
@Component
export default class VariantAnnotationView extends Vue {
  @Prop() value!: VariantAnnotation;
  private variant: VariantAnnotation = new VariantAnnotation({});

  private mounted() {
    this.variant = this.value;
  }

  @Watch("value")
  private updateValue() {
    this.variant = new VariantAnnotation(this.value);
  }

  private onUpdate() {
    this.$emit("input", this.variant);
  }
}
