





import { Component, Vue } from "vue-property-decorator";
import { BFormInput, BFormCheckbox, BFormSelect } from "bootstrap-vue";
// TODO: this will be the editor wrapper that wraps editing all the things
@Component
export default class Editor extends Vue {
  private model: any = {};
  private mounted() {
    // TODO: load the target item
  }
  // TODO: take an options prop that has options by key that get bound?
  private type(val: any) {
    if (typeof val == "boolean") return BFormCheckbox;
    if (typeof val == "string") return BFormInput;
    if (val instanceof Array) return BFormSelect;
  }
}
