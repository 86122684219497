





















/* 
WHATS CHANGEABLE
can change the css and render type of category and options inside
can change the material reference that a material setting points at
*/
import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import {
  Option,
  Debug,
  Category,
  Configurator,
} from "@monowarestudios/js-util";
import OptionSettings from "@/components/product_manager_views/config_details_views/option/OptionSettings.vue";
import OptionApiConfiguration from "@/components/product_manager_views/config_details_views/option/OptionApiConfiguration.vue";
import OptionUIConfiguration from "@/components/product_manager_views/config_details_views/option/OptionUIConfiguration.vue";
import RenderSettingsView from "@/components/product_manager_views/config_details_views/RenderSettingsView.vue";

@Component({
  components: {
    RenderSettingsView,
    OptionSettings,
    OptionApiConfiguration,
    "option-ui-configuration": OptionUIConfiguration,
  },
})
export default class OptionOverrideDetailsView extends Vue {
  private option: Option = new Option();
  private status = "";
  @Prop() clientID!: number;
  private created = false;
  @Prop() debug!: Debug;
  @Prop() existingCategories!: Category[];
  @Prop() configurator!: Configurator;
  @Prop() category!: string;
  @Ref() optCssSettings!: RenderSettingsView;
  @Ref() optSettings!: OptionSettings;
  @Ref() optionApi!: OptionApiConfiguration;
  @Ref() optionUi!: OptionUIConfiguration;

  private hide() {
    this.$emit("hide");
  }
  public async set(option: Option | null) {
    this.option = option ? option : new Option();
    this.created = Boolean(this.option.internalName);
  }

  private showOptionSettings() {
    this.optSettings.show();
  }

  private showCssSettings() {
    this.optCssSettings.show();
  }

  private editAPIData() {
    this.optionApi.show();
  }

  private editUIData() {
    this.optionUi.show();
  }

  private async save() {
    this.status = "";
    if (this.option.displayName != "") {
      this.$emit("save", this.option);
    } else {
      this.status = "Option requires display name";
    }
  }
}
