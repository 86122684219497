












































































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { currencyList, Variant, EmptyVariant, Category, type Currency } from "@monowarestudios/js-util";
import VariantAnnotation from "./VariantAnnotation.vue";
@Component({ components: { VariantAnnotation } })
export default class VariantView extends Vue {
  @Prop() value!: Variant;
  @Prop() categories!: Category[];
  private variant: Variant = EmptyVariant();

  private get options() {
    return (
      this.categories
        .map((c: Category) => [
          ...c.options.map(o => `${c.internalName}::${o.internalName}`),
          `${c.internalName}::*`,
        ])
        .reduce((acc, curr) => [...acc, ...curr], []) || []
    );
  }

  private mounted() {
    this.variant = this.value;
  }

  @Watch("value", {deep:true})
  private updateValue() {
    this.variant = this.value;
  }

  private onUpdate() {
    this.variant.optionMappings.forEach(
      o => (o.defaultQuantity = parseInt(o.defaultQuantity as any))
    );
    this.variant.price = parseFloat(this.variant.price as any ||"0")
    this.variant.prices = this.variant.prices.map((c:Currency) => ({...c, price: parseInt(c.price as any || "0")}))
    this.$emit("input", this.variant);
  }

  private addMapping() {
    this.variant.optionMappings.push({
      mapping: [],
      defaultQuantity: 1,
      quantityOption: "",
      excludedBy: [],
    });
    this.onUpdate()
  }
  private removeMapping(index: number) {
    this.variant.optionMappings.splice(index, 1);
    this.onUpdate()
  }
  private addPrice() {
    this.variant.prices.push({code: "", currency: "", price:0});
    this.onUpdate();
  }
  private removePrice(index:number) {
    this.variant.prices.splice(index, 1);
    this.onUpdate();
  }
  private codes = currencyList.sort((a:Currency, b:Currency) => a.currency >= b.currency ? 1 : -1 ).map((c: Currency) => ({ text: c.currency, value: c.code }));

}
