











































// TODO: mappings for forms, types
import { Component, Prop, Vue, Ref, PropSync } from "vue-property-decorator";
import {
  HubspotInfo,
  Debug,
  FormInput,
  floatingShareableModes,
  validationModes,
StandaloneFormSetting,
StandaloneFormInputState,
Variant,
} from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";
@Component({ components: { BModalWrapper } })
export default class HubspotMappings extends Vue {
  @Prop() debug!: Debug;
  @Prop() variants!: any[];
  @Prop() products!: any[];
  @Prop() value!: HubspotInfo;
  @Ref() modal!: BModalWrapper;
  private get availableVariants() {
    return this.variants.map((v:Variant) => ({
      value: v,
      text: v.title
    }))
  }
  private model: HubspotInfo = {
    portalId: "",
    formGuid: "",
    mappings: [],
  };
  private selected = 0
  private selectedForm = 0
  private fieldTypes = [
    'text',
    'checkbox',
    'multiCheckbox',
    'select',
  ]
  private objectTypeOptions = ["0-1", "0-2", "0-3", "0-5"]
  public show() {
    this.model = JSON.parse(JSON.stringify({ ...this.model || {}, ...this.value }));
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  public save() {
    this.$emit("input", this.model);
    this.hide();
  }
  private addMapping() {
    this.model.mappings = [...this.model.mappings, {} as any]
    this.selected = this.model.mappings.length - 1;
    this.$forceUpdate();
  }

  private deleteMapping(index: number) {
    this.model.mappings.splice(index, 1);
    this.selected = this.model.mappings.length - 1;
    this.$forceUpdate();
  }

  private selectMapping(index: number) {
    this.selected = index;
  }

  private addVariantIDMapping(selected: number) {
    this.model.mappings[selected].variantIDMap.push({
      variantID: "",
      hubspotOption: "",
    })
  }
  private removeVariantIDMapping(selected: number, index:number) {
    this.model.mappings[selected].variantIDMap.splice(index, 1)
  }
}
