












import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import { Requests, Color, Debug, EmptyColor } from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";

@Component({ components: { BModalWrapper } })
export default class EditColorView extends Vue {
  @Prop() private propClientID!: number;
  @Prop() debug!: Debug;
  @Ref() modal!: BModalWrapper;
  private status = "";
  private color: Color = EmptyColor();
  private hex = "";

  private async getData(id: number) {
    try {
      const [data, success] = await Requests.get("color", id);
      if (success) {
        this.color = data as Color;
      } else {
        this.status = data as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }

  private onColorChange(colorString: string) {
    if (colorString[0] === "#") {
      this.hex = colorString;
      return;
    }
    this.hex = "";
  }
  
  private parseHex(code: string) {
    const red = parseInt(code.slice(1, 3), 16) / 255;
    const green = parseInt(code.slice(3, 5), 16) / 255;
    const blue = parseInt(code.slice(5, 7), 16) / 255;
    const alpha = parseInt(code.slice(7, 9) || "FF", 16) / 255;

    return `${red},${green},${blue},${alpha}`;
  }

  public show(id: number) {
    if (id != 0) {
      this.getData(id);
    } else {
      this.color = { ...EmptyColor(), clientID: this.propClientID };
    }
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  private cancel() {
    this.hide();
  }

  private get previewColor() {
    if(this.hex) return this.hex
    const split = this.color.color.split(",").map(parseFloat);
    const alpha = split.length == 4 ? split.pop() : 1;
    const fixed = split.map(n => n * 255);

    return `rgba(${[...fixed, alpha].join(",")}`;
  }

  private async save() {
    this.status = "";
    try {
      if(this.hex) this.color.color = this.parseHex(this.color.color)
      // Note that creation is handled by the bulk editor, CreateColorsView
      const [data, success] = await Requests.update("color", this.color);
      if (success) {
        this.status = "Data saved!";
        this.$emit("created");
        this.hex = ""
      } else {
        this.status = ("Failed to save data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }
}
