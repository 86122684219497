













import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import {
  Requests,
  RenderSettings,
  ClientCSS,
  EmptyClientCSS,
  RenderTypes,
  Debug,
} from "@monowarestudios/js-util";
import { parseCSS, encodeCSS } from "@/css";
import BModalWrapper from "@/components/BModalWrapper.vue";

type StringifiedRenderType = {
  renderType: string;
  CSS: string;
};

@Component({ components: { BModalWrapper } })
export default class EditCSSView extends Vue {
  @Prop() private propClientID!: number;
  @Ref() modal!: BModalWrapper;
  private status = "";
  private css: ClientCSS = EmptyClientCSS();
  private config: StringifiedRenderType[] = [];
  private category: StringifiedRenderType[] = [];
  private option: StringifiedRenderType[] = [];
  private scopesCss: {
    // css for the different scopes: config, category, option
    [scope: string]: {
      currRenderType: string;
      [renderType: string]: string; // maps render type to a string of css for that render type
    };
  } = {
    config: { currRenderType: "" },
    category: { currRenderType: "" },
    option: { currRenderType: "" },
  };
  private renderTypes = RenderTypes;
  @Prop() debug!: Debug;

  created() {
    const getRenderTypesCss = (srts: StringifiedRenderType[]) => {
      return srts.reduce<{ [renderType: string]: string }>(
        (prevVal, currVal) => {
          const nextVal = prevVal;
          const currRT = currVal.renderType;
          if (currRT in prevVal) {
            const prevCss = prevVal[currRT];
            nextVal[currRT] = prevCss + currVal.CSS;
          } else {
            nextVal[currRT] = currVal.CSS;
          }

          return nextVal;
        },
        {}
      );
    };

    this.scopesCss = {
      config: { currRenderType: "", ...getRenderTypesCss(this.config) },
      category: { currRenderType: "", ...getRenderTypesCss(this.category) },
      option: { currRenderType: "", ...getRenderTypesCss(this.option) },
    };
  }

  private getRenderTypes(scope: string) {
    const scopeCss = this.scopesCss[scope];

    return Object.keys(scopeCss).filter(key => key !== "currRenderType");
  }

  private getCurrRenderType(scope: string) {
    return this.scopesCss[scope].currRenderType;
  }

  private parseCSS(): ClientCSS {
    // for each, split the string on new line and construct object split on
    return {
      clientID: this.propClientID,
      id: this.css.id,
      name: this.css.name,
      config: this.config.map((item: StringifiedRenderType) => ({
        renderType: item.renderType,
        css: parseCSS(item.CSS),
        description: "",
        priceDisplay: "",
        useTooltip: false,
        useToggle: false,
        tooltipText: "",
        imageUrl: "",
        imageID: 0,
        mediaQueries: [],
        alwaysShowDescription: false,
        optionShowDescriptionIfSelected: false,
        categoryShowSelectedOptionDescription: false,
        categoryShowOptionPreventions: false,
      })),
      category: this.category.map((item: StringifiedRenderType) => ({
        renderType: item.renderType,
        css: parseCSS(item.CSS),
        description: "",
        priceDisplay: "",
        useTooltip: false,
        useToggle: false,
        tooltipText: "",
        imageUrl: "",
        imageID: 0,
        mediaQueries: [],
        alwaysShowDescription: false,
        optionShowDescriptionIfSelected: false,
        categoryShowSelectedOptionDescription: false,
        categoryShowOptionPreventions: false,
      })),
      option: this.option.map((item: StringifiedRenderType) => ({
        renderType: item.renderType,
        css: parseCSS(item.CSS),
        description: "",
        priceDisplay: "",
        useTooltip: false,
        useToggle: false,
        tooltipText: "",
        imageUrl: "",
        imageID: 0,
        mediaQueries: [],
        alwaysShowDescription: false,
        optionShowDescriptionIfSelected: false,
        categoryShowSelectedOptionDescription: false,
        categoryShowOptionPreventions: false,
      })),
    };
  }

  private encodeCSS() {
    const toRTS = (scope: string) => {
      return this.getRenderTypes(scope).map(renderType => {
        const CSS = this.scopesCss[scope][renderType];
        return {
          renderType,
          CSS,
        };
      });
    };

    this.config = toRTS("config");
    this.category = toRTS("category");
    this.option = toRTS("option");
  }

  private async getData(id: number): Promise<ClientCSS> {
    try {
      const [data, success] = await Requests.get("css", id);
      if (success) {
        return data as ClientCSS;
      } else {
        this.status = data as string;
      }
    } catch (err) {
      this.status = err as string;
    }
    return EmptyClientCSS();
  }

  public async show(id: number) {
    this.css = id != 0 ? await this.getData(id) : EmptyClientCSS();
    this.encodeCSS();
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  private cancel() {
    this.hide();
  }

  private async save() {
    this.status = "";
    this.css = this.parseCSS();
    try {
      const [data, success] = await (this.css!.id == 0
        ? Requests.create("css", this.css)
        : Requests.update("css", this.css));
      if (success) {
        this.status = "Data saved!";
        this.$emit("created");
        if (this.css!.id == 0) this.css!.id = data as number;
      } else {
        this.status = ("Failed to save data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }
}
