import { defineStore } from "pinia";
import { CategorySet } from "./StoreTypes";

interface StoreState {
  categorySets: Map<string, CategorySet>; //category set id to category set
  currentSet: string; // current set id
}

export const useCategorySetStore = defineStore({
  id: "CategorySetStore",
  state: (): StoreState => ({
    categorySets: new Map(),
    currentSet: "",
  }),
  getters: {
    getSet(state) {
      return (setInternalName: string) => {
        return state.categorySets.get(setInternalName);
      };
    },
    getCurrentSet(): CategorySet | undefined {
      return this.getSet(this.currentSet);
    },
    getSets() {
      return (setInternalNames: string[]) => {
        return setInternalNames.map(internalName => {
          return this.categorySets.get(internalName);
        });
      };
    },
    getAllSets(state) {
      return Array.from(state.categorySets.values());
    },
  },
  actions: {
    addSets(categorySets: CategorySet[]) {
      categorySets.forEach(cs => {
        this.addSet(cs);
      });
    },
    addSet(categorySet: CategorySet) {
      return this.categorySets.set(categorySet.internalName, categorySet);
    },
    updateCurrentCategorySet(setInternalName: string) {
      const set = this.getSet(setInternalName);

      if (!set) return undefined;

      this.currentSet = setInternalName;
    },
    addCurrentSetCategories(setInternalName: string, internalNames: string[]) {
      const set = this.getSet(setInternalName);

      if (!set) return undefined;

      internalNames.forEach(internalName => {
        set.currentCategories.add(internalName);
      });
    },
    removeSet(internalName: string) {
      this.categorySets.delete(internalName);
    },
    removeCurrentSetCategories(
      setInternalName: string,
      internalNames: string[]
    ) {
      const set = this.getSet(setInternalName);

      if (!set) return undefined;

      internalNames.forEach(internalName => {
        set.currentCategories.delete(internalName);
      });
    },
    addCategoryToSet(setInternalName: string, internalName: string) {
      const set = this.getSet(setInternalName);
      if (!set) return undefined;

      return set.categories.add(internalName);
    },
    removeCategoryFromSet(setInternalName: string, internalName: string) {
      const set = this.getSet(setInternalName);

      if (!set) return undefined;

      return set.categories.delete(internalName);
    },
    updateCategorySetVisibility(setInternalName: string, visible: boolean) {
      const set = this.getSet(setInternalName);

      if (!set) return undefined;

      return (set.isVisible = visible);
    },
    updateCategorySetCssClasses(setInternalName: string, classes: string[]) {
      const set = this.getSet(setInternalName);

      if (!set) return undefined;

      if (!set.classes) return (set.classes = classes);

      return (set.classes = [...set.classes, ...classes]);
    },
    updateCategorySetCssStyles(setInternalName: string, styles: string) {
      const set = this.getSet(setInternalName);

      if (!set) return undefined;

      if (!set.styles) return (set.styles = styles);

      return (set.styles = set.styles.concat(styles));
    },
  },
});

export default useCategorySetStore;
