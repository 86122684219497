






























import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";
import {
  Debug,
  Variant,
  Category,
  CategoryOverride,
  Option,
  Product,
  Requests,
  VariantSet,
  Config,
} from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";
import VariantView from "./VariantView.vue";
import VariantSetView from "./VariantSetView.vue";

@Component({ components: { VariantSetView, VariantView, BModalWrapper } })
export default class ConfigVariantEditor extends Vue {
  @Prop() debug!: Debug;
  @Prop() value!: VariantSet[];
  @Prop() products!: Product[];
  @Prop() configs!: Config[];
  @Prop() categories!: Category[];
  @Ref() modal!: BModalWrapper;
  private variants: VariantSet[] = [];
  private selectedVariantImport: Product | null = null;
  private selectedConfigImport: Config | null = null;
  private status = "";
  private search = "";
  private index = 0;
  private get filteredVariants() {
    return this.variants.filter((p:VariantSet)  => p.productName.toLowerCase().includes(this.search.toLowerCase()) || p.productID.toLowerCase().includes(this.search.toLowerCase()) ||!this.search)
  }
  private onChange(evt: any) {
    if (evt.moved) {
      if (evt.moved.oldIndex == this.index) {
        this.index = evt.moved.newIndex;
      }
    }
  }

  private edit(index: number) {
    this.index = index;
  }

  public async show() {
    this.reset();
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  private reset() {
    this.variants = this.value || [];
  }

  private save() {
    this.$emit("input", this.variants);
    this.hide();
  }

  private remove(index: number) {
    this.variants.splice(index, 1);
  }
  private copy(index: number) {
    this.variants = [
      ...this.variants.slice(0, index),
      JSON.parse(JSON.stringify(this.variants[index])),
      ...this.variants.slice(index),
    ]
    this.$forceUpdate();
  }

  private cancel() {
    this.hide();
  }

  private async importVariants() {
    if (this.selectedVariantImport) {
      const [data, success] = await Requests.get(
        "product",
        this.selectedVariantImport.id
      );
      if (success) {
        this.variants.push({
          variants: (data as Product).variants,
          productID: (data as Product).productExternalID,
          productHandle: (data as Product).productHandle,
          productName: (data as Product).name,
          cartCategories: [],
          lineCategories: [],
        });
      } else {
        this.status = data as string;
      }
    } else {
      this.status = "Select a source product";
    }
  }

  private async importVariantsFromConfig() {
    if (this.selectedConfigImport) {
      const [data, success] = await Requests.get(
        "config",
        this.selectedConfigImport.id
      );
      if (success) {
        this.variants.push(...(data as Config).variantMap);
      } else {
        this.status = data as string;
      }
    } else {
      this.status = "Select a source config";
    }
  }
}
