












import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";
import {
  Requests,
  Texture,
  Debug,
  EmptyTexture,
  PresignedUrlInfo,
} from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";
import ImageUpload from "@/components/ImageUpload.vue";

type TextureUpload = { imageUpload: PresignedUrlInfo; texture: Texture };

@Component({ components: { BModalWrapper, ImageUpload } })
export default class CreateTexturesView extends Vue {
  @Prop() private propClientID!: number;
  @Prop() debug!: Debug;
  @Ref() modal!: BModalWrapper;

  private status = "";
  private uploads: TextureUpload[] = [];

  public show() {
    this.uploads = [];
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  private cancel() {
    this.hide();
  }

  private add() {
    this.uploads.push({
      texture: { ...EmptyTexture(), clientID: this.propClientID },
      imageUpload: { key: "", expires: new Date(), url: "", uploadUrl: "" },
    });
  }

  private async save() {
    this.status = "";
    const inputs = this.uploads.map(u => ({
      ...u.texture,
      key: u.imageUpload.key,
      path: u.imageUpload.url,
    }));
    if (!inputs.every(i => i.path && i.key)) {
      this.status =
        "Not all images have been uploaded, either upload or remove unused";
      return;
    }
    try {
      const [data, success] = await Requests.create("texture", inputs);
      if (success) {
        this.status = "Successfully created";
        this.uploads = [];
        this.$emit("created");
      } else {
        this.status = ("Failed to save data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }
}
