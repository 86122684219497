import { render, staticRenderFns } from "./EditCategoryTemplate.vue?vue&type=template&id=52926a0b&scoped=true&lang=pug&"
import script from "./EditCategoryTemplate.vue?vue&type=script&lang=ts&"
export * from "./EditCategoryTemplate.vue?vue&type=script&lang=ts&"
import style0 from "./EditCategoryTemplate.vue?vue&type=style&index=0&id=52926a0b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52926a0b",
  null
  
)

export default component.exports