



















import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import { Confirmation, Debug } from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";

@Component({ components: { BModalWrapper } })
export default class EditConfirmation extends Vue {
  @Prop() debug!: Debug;
  @Prop() value!: Confirmation;
  @Ref() modal!: BModalWrapper;
  private confirmation: Confirmation = {} as any;

  private mounted() {
    this.confirmation = JSON.parse(JSON.stringify(this.value));
  }

  public save() {
    this.$emit("input", this.confirmation);
  }

  private get noChanges() {
    return (
      this.confirmation.internalName != this.value.internalName ||
      this.confirmation.text != this.value.text ||
      this.confirmation.type != this.value.type ||
      this.confirmation.required != this.value.required
    );
  }
}
