





















import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { AOPBRSetting, Debug } from "@monowarestudios/js-util";
import SelectDeployable from "@/components/SelectDeployable.vue";

@Component({ components: { SelectDeployable } })
export default class AopbrSettingEditor extends Vue {
  @Prop() value!: AOPBRSetting;
  @Prop() title!: string;
  @Prop() debug!: Debug;
  @Prop() propClientID!: number;
}
