













import { Component, Watch, Prop, Vue } from "vue-property-decorator";

import { ValidationCondition } from "@monowarestudios/js-util";

@Component
export default class ValidationConditionEditor extends Vue {
  @Prop() value!: ValidationCondition;
  @Prop() options!: string[];
  private condition: ValidationCondition = {
    reference: "",
    key: "",
    value: "",
  };

  @Watch("value")
  private onUsageChange() {
    this.condition = JSON.parse(JSON.stringify(this.value));
  }
  private mounted() {
    this.onUsageChange();
  }

  @Watch("condition", {deep: true})
  private save(oldval:any) {
    if(JSON.stringify(oldval) != JSON.stringify(this.value))
    this.$emit("input", this.condition);
  }
}
