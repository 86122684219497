import { defineStore } from "pinia";
import { useCategoryOptionStore } from "./CategoryOptionStore";
import { useCategorySetStore } from "./CategorySetStore";
import { SetCategory } from "./StoreTypes";

interface StoreState {
  setCategories: Map<string, SetCategory>; // categories internal id to the set category
}

export const useSetCategoryStore = defineStore({
  id: "SetCategoryStore",
  state: (): StoreState => ({
    setCategories: new Map(),
  }),
  getters: {
    getSetCategories() {
      const categorySetStore = useCategorySetStore();

      return (setId: string) => {
        const set = categorySetStore.getSet(setId);

        if (!set) return undefined;

        const categories: SetCategory[] = [];

        [...set.categories].length !== 0 &&
          console.log({ c: set.categories.entries() });

        set.categories.forEach((internalName: string) => {
          const category = this.setCategories.get(internalName);

          if (!category) return undefined;

          categories.push(category);
        });

        return categories;
      };
    },
    getNextSetCategory() {
      const categorySetStore = useCategorySetStore();

      return (setId: string, categoryInternalName: string) => {
        const set = categorySetStore.getSet(setId);

        if (!set) return undefined;

        const categories = this.getSetCategories(setId);

        if (!categories) return undefined;

        for (let i = 0; i < categories.length; i++) {
          // no next element as we must be on the last category
          if (i + 1 >= categories.length) return undefined;

          const currCategory = categories[i];
          const nextCategory = categories[i + 1];

          if (
            currCategory.category &&
            currCategory.category.internalName === categoryInternalName
          )
            return nextCategory;
        }
      };
    },
    getPrevSetCategory() {
      const categorySetStore = useCategorySetStore();

      return (setId: string, categoryInternalName: string) => {
        const set = categorySetStore.getSet(setId);

        if (!set) return undefined;

        const categories = this.getSetCategories(setId);

        if (!categories) return undefined;

        for (let i = categories.length - 1; i >= 0; i--) {
          // no prev element as we must be on the first category
          if (i - 1 < 0) return undefined;

          const prevCategory = categories[i - 1];
          const currCategory = categories[i];

          if (
            currCategory.category &&
            currCategory.category.internalName === categoryInternalName
          )
            return prevCategory;
        }
      };
    },
    getSetCategory() {
      return (categoryInternalName: string) => {
        return this.setCategories.get(categoryInternalName);
      };
    },
    getCategoriesFromInternalNames() {
      return (internalNames: string[]) => {
        return internalNames
          .map(internalName => this.getSetCategory(internalName))
          .filter(cat => cat !== undefined);
      };
    },
  },
  actions: {
    addSetCategory(setCategory: SetCategory) {
      if (!setCategory.category) return;

      return this.setCategories.set(
        setCategory.category.internalName,
        setCategory
      );
    },
    addSetCategories(setCategories: SetCategory[]) {
      setCategories.forEach(setCategory => {
        this.addSetCategory(setCategory);
      });
    },
    changeCategoryRef(internalName: string, ref: any) {
      return [internalName, ref];
    },
    // causes infinite recursion if cyclic graph
    updateSetCategoryVisibility(internalName: string, visible: boolean) {
      const setCategory = this.setCategories.get(internalName);

      if (!setCategory) return undefined;

      setCategory.isVisible = visible;

      const categorySetStore = useCategorySetStore();
      const categoryOptionStore = useCategoryOptionStore();

      setCategory.needThisCategoryNotVisibleToShow.categorySets.forEach(
        (csId: any) => {
          const set = categorySetStore.getSet(csId);

          if (!set) return undefined;

          const setIsVisible = set.isVisible && !visible;

          return categorySetStore.updateCategorySetVisibility(
            csId,
            setIsVisible
          );
        }
      );
      setCategory.needThisCategoryNotVisibleToShow.setCategories.forEach(
        (scInternalName: any) => {
          const category = this.setCategories.get(scInternalName);

          if (!category) return undefined;

          const categoryIsVisible = category.isVisible && !visible;

          return this.updateSetCategoryVisibility(
            scInternalName,
            categoryIsVisible
          );
        }
      );
      setCategory.needThisCategoryNotVisibleToShow.categoryOptions.forEach(
        (coInternalName: any) => {
          const option = categoryOptionStore.getOption(coInternalName);

          if (!option) return undefined;

          const optionIsVisible = option.isVisible && !visible;

          return categoryOptionStore.updateOptionVisibility(
            coInternalName,
            optionIsVisible
          );
        }
      );

      setCategory.needThisCategoryVisibleToShow.categorySets.forEach(
        (csId: any) => {
          const set = categorySetStore.getSet(csId);

          if (!set) return undefined;

          const setIsVisible = set.isVisible && !visible;

          return categorySetStore.updateCategorySetVisibility(
            csId,
            setIsVisible
          );
        }
      );
      setCategory.needThisCategoryVisibleToShow.setCategories.forEach(
        (scInternalName: any) => {
          const category = this.setCategories.get(scInternalName);

          if (!category) return undefined;

          const categoryIsVisible = category.isVisible && !visible;

          return this.updateSetCategoryVisibility(
            scInternalName,
            categoryIsVisible
          );
        }
      );
      setCategory.needThisCategoryVisibleToShow.categoryOptions.forEach(
        (coInternalName: any) => {
          const option = categoryOptionStore.getOption(coInternalName);

          if (!option) return undefined;

          const optionIsVisible = option.isVisible && !visible;

          return categoryOptionStore.updateOptionVisibility(
            coInternalName,
            optionIsVisible
          );
        }
      );
    },
    updateSetCategoryCssClasses(internalName: string, classes: string[]) {
      const category = this.setCategories.get(internalName);

      if (!category) return undefined;

      if (!category.classes) return (category.classes = classes);

      return (category.classes = [...category.classes, ...classes]);
    },
    updateSetCategoryCssStyles(internalName: string, styles: string) {
      const category = this.setCategories.get(internalName);

      if (!category) return undefined;

      if (!category.styles) return (category.styles = styles);

      return (category.styles = category.styles.concat(styles));
    },
  },
});

export default useSetCategoryStore;
