

































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { Requests, Client, Debug } from "@monowarestudios/js-util";
import Cookies from "js-cookie";
import EditClientView from "@/components/EditClientView.vue";
import EditTextureView from "@/components/EditTextureView.vue";
import EditColorView from "@/components/EditColorView.vue";
import CreateTexturesView from "@/components/CreateTexturesView.vue";
import CreateColorsView from "@/components/CreateColorsView.vue";
import EditEmployeeView from "@/components/EditEmployeeView.vue";
import EditCssView from "@/components/EditCSSView.vue";
import EditCategoryTemplate from "@/components/EditCategoryTemplate.vue";
import { BTable } from "bootstrap-vue";
import EditCartView from "../components/EditCartView.vue";
import EditOrderDetailsView from "../components/EditOrderDetailsView.vue";
interface MWViewModal extends Vue {
  show: (id: number) => void;
  hide: (id: number) => void;
}
type FailedEmail = {
  error: string;
  subject: string;
  content: string;
  email: string;
  id: number;
};

@Component({
  components: {
    EditClientView,
    CreateTexturesView,
    CreateColorsView,
    EditTextureView,
    EditColorView,
    EditEmployeeView,
    EditCssView,
    EditCategoryTemplate,
    EditCartView,
    EditOrderDetailsView
  },
})
export default class BrowseComponent extends Vue {
  private scope = 0;
  private availableScopes: any[] = [];
  private status = "";
  private currentPage = 1;
  private rows = 0;
  private components: Record<string, string> = {
    client: "edit-client",
    texture: "edit-texture-view",
    color: "edit-color",
    employee: "edit-employee-view",
    css: "edit-css",
    category: "edit-category",
    store: "store-edit",
    application: "application-review",
    removal: "removal-request-review",
    cartConfig: "edit-cart-view",
    orderDetailsConfig: "edit-order-details-view"
  };
  private headers = {
    category: ["id", "clientID", "templateName"].map(i => ({
      key: i,
      sortable: true,
    })),
    store: ["name", "id", "url"].map(i => ({ key: i, sortable: true })),
    application: ["name", "status"].map(i => ({ key: i, sortable: true })),
    removal: ["storeID"].map(i => ({ key: i, sortable: true })),
    product: ["name", "id", "productExternalID"].map(i => ({
      key: i,
      sortable: true,
    })),
    client: ["name", "id", "subscribed", "person", "email"].map(i => ({
      key: i,
      sortable: true,
    })),
    employee: ["person", "id", "email"].map(i => ({ key: i, sortable: true })),
    color: ["name", "id"].map(i => ({ key: i, sortable: true })),
    css: ["name", "id"].map(i => ({ key: i, sortable: true })),
    texture: [
      ...["name", "id"].map(i => ({ key: i, sortable: true })),
      "thumbnail",
    ],
    email: ["subject", "email", "error"].map(i => ({ key: i, sortable: true })),
    cartConfig: ["clientID"].map(i => ({ key: i, sortable: true })),
    orderDetailsConfig: ["clientID"].map(i => ({ key: i, sortable: true }))
  };
  @Prop() debug!: Debug;

  @Watch("scope")
  private onScopeChange() {
    this.status = "";
    (this.$refs["table"] as BTable).refresh();
  }

  @Watch("$route.params.type", { deep: true })
  private onTypeChange(newVal: string, oldVal: string) {
    // oldval, store filter/sort settings in cooke - newval, try to load from cookie else set defaults
    this.saveSettings(oldVal);
    this.loadSettings(newVal);
    this.status = "";
    if (this.requiresScope) {
      this.loadScopes();
    }
    (this.$refs["table"] as BTable).refresh();
  }

  get noAdd() {
    return this.$route.params.type === 'email' || this.$route.params.type === 'cartConfig' || this.$route.params.type === 'orderDetailsConfig'
  }

  private productManager(productID: number) {
    // TODO: CODE OVERHAUL - save state here - probably should be in a dismout hook?/??
    this.$router.push(`/productmanager/${this.scope}/${productID}`);
  }

  private loadSettings(key: string) {
    const settings: { [index: string]: any } = JSON.parse(
      Cookies.get(`browse-settings-${key}`) || "{}"
    );
    // stored: current page, any filter settings - should have a filter component thing that we instaitnate and then add that
    // we can pull from the type def of the thing we are browsing
  }

  private saveSettings(key: string) {
    const settings: { [index: string]: any } = {};
    settings["currentPage"] = this.currentPage;
    settings["filterSettings"] = "";
    settings["sortSettings"] = "";
    Cookies.set(`browse-settings-${key}`, JSON.stringify(settings));
  }

  private get requiresScope(): boolean {
    return ["texture", "color", "product", "css", "category"].includes(
      this.$route.params.type
    );
  }

  private async loadScopes() {
    try {
      const [data, success] = await Requests.list("client");
      if (success) {
        this.availableScopes = data as Client[];
      } else {
        this.status = ("Failed to load data!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }

  private sortChanged(ctx: any) {
    (this.$refs["table"] as BTable).refresh();
  }

  private genData(ctx: any, callback: any) {
    this.getData(ctx)
      .then(([data, success]) => {
        if (success) {
          this.rows = data["count"] as number;
          callback(data["data"] as any[]);
        } else {
          callback([]);
          this.status = "Could not load table";
        }
      })
      .catch(err => {
        this.status = err as string;
        callback([]);
      });
    return null;
  }
  /*
  currentPage	Number	The current page number (starting from 1, the value of the current-page prop)
  perPage	Number	The maximum number of rows per page to display (the value of the per-page prop)
  filter	String or RegExp or Object	the value of the filter prop
  sortBy	String	The current column key being sorted, or an empty string if not sorting
  sortDesc	Boolean	The current sort direction (true for descending, false for ascending)
  */
  private async getData(ctx: any) {
    const index = (ctx.currentPage - 1) * 50;
    const sortBy = ctx.sortBy.replace(/([A-Z]+)/g, "_$1").toLowerCase();
    if (this.requiresScope) {
      if (this.scope !== 0) {
        return await Requests.browseScoped(
          this.$route.params.type,
          index,
          this.scope,
          sortBy,
          ctx.sortDesc
        );
      } else {
        throw Error("Select a scope");
      }
    } else {
      return await Requests.browse(
        this.$route.params.type,
        index,
        sortBy,
        ctx.sortDesc
      );
    }
  }

  private onAdd() {
    switch (this.$route.params.type) {
      case "product":
        this.$router.push(`/productmanager/${this.scope}/0`);
        break;
      case "texture":
        (this.$refs["create-texture"] as MWViewModal).show(0);
        break;
      case "color":
        (this.$refs["create-color"] as MWViewModal).show(0);
        break;
      case "category":
        (this.$refs["edit-category"] as MWViewModal).show(0);
        break;
      case "css":
        (this.$refs["edit-css"] as MWViewModal).show(0);
        break;
      case "cartConfig":
        (this.$refs["edit-cart-view"] as MWViewModal).show(0);
        break;
      case "orderDetailsConfig":
        (this.$refs["edit-order-details-view"] as MWViewModal).show(0);
        break;
      default:
        (this.$refs[
          this.components[this.$route.params.type]
        ] as MWViewModal).show(0);
        break;
    }
  }

  private async onSelect(data: number) {
    (this.$refs[this.components[this.$route.params.type]] as MWViewModal).show(
      data
    );
  }

  private mounted() {
    this.onTypeChange(this.$route.params.type, "");
  }

  private onCreated() {
    (this.$refs["table"] as BTable).refresh();
  }

  private async deleteItem(id: number) {
    const conf = await this.$bvModal.msgBoxConfirm(
      `Confirm deletion of ${id}?`,
      { title: "Confirm delete" }
    );
    if (conf) {
      try {
        const [data, success] = await Requests.delete(
          this.$route.params.type,
          id
        );
        if (success) {
          (this.$refs["table"] as BTable).refresh();
        } else {
          this.status = "Failed to delete!\n" + data;
        }
      } catch (err) {
        this.status = err as string;
      }
    }
  }

  private async retryEmail(id: number) {
    this.status = "";
    try {
      const [data, success] = await Requests.retryEmail(id);
      if (success) {
        this.status = "Email resent!";
        (this.$refs["table"] as BTable).refresh();
      } else {
        this.status = ("Failed to resend email!\n" + data) as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }
}
