












































































































































import useCategoryOptionStore from "@/stores/CategoryOptionStore";
import useConfigStore from "@/stores/ConfigStore";
import useSetCategoryStore from "@/stores/SetCategoryStore";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { CategoryOption, SetCategory } from "../../../../stores/StoreTypes";

export default defineComponent({
  props: {
    configId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const configStore = useConfigStore();
    const setCategoryStore = useSetCategoryStore();
    const categoryOptionStore = useCategoryOptionStore();

    const categorySetNames = computed(
      () =>
        configStore
          .getCategorySets(props.configId)
          .map((set: any) => set.internalName) || []
    );

    const possibleCategories = computed(() =>
      configStore.getPossibleCategories(props.configId)
    );
    const possibleCategoryNames = computed(() =>
      possibleCategories.value.map(
        (pc: SetCategory | undefined) => pc?.categoryInternalName
      )
    );

    const isAddingCategoryDependency = ref(false);
    const currUpdatingDependenciesCategoryName = ref("");
    const currUpdatingDependenciesCategory = ref<SetCategory>();
    watch(
      () => currUpdatingDependenciesCategoryName.value,
      value => {
        currUpdatingDependenciesCategory.value = setCategoryStore.getSetCategory(
          value
        );
      }
    );
    const updateCategoryDependencies = (e: Event) => {
      isAddingCategoryDependency.value = false;

      if (!currUpdatingDependenciesCategory.value) return;

      setCategoryStore.addSetCategory(currUpdatingDependenciesCategory.value);

      /*  configStore.addSetCategory(
        props.configId,
        currUpdatingDependenciesCategory.value
      ); */
    };

    const possibleOptions = computed(() => {
      return possibleCategories.value.flatMap((pc: SetCategory | undefined) => {
        return (
          categoryOptionStore.getOptions(pc?.categoryInternalName || "") || []
        );
      });
    });
    const possibleOptionNames = computed(() => {
      return possibleOptions.value.map(
        (o: CategoryOption) => `${o.categoryId}::${o.optionInternalName}`
      );
    });
    const categoryOptions = computed(() => {
      const category = setCategoryStore.getSetCategory(
        currUpdatingDependenciesCategoryName.value
      );

      if (!category) return [];

      const optionInternalNames = Array.from(category.options);

      return (
        optionInternalNames
          .map(internalName =>
            categoryOptionStore.getOption(internalName as string)
          )
          .filter(option => option !== undefined) || []
      );
    });
    const categoryOptionNames = computed(() => {
      const options = categoryOptions.value;

      return (
        options
          .map(option => option?.optionInternalName)
          .filter(option => option !== undefined) || []
      );
    });

    const isAddingOptionDependency = ref(false);
    const currUpdatingDependenciesOptionName = ref("");
    const currUpdatingDependenciesOption = ref<CategoryOption>();
    watch(
      () => currUpdatingDependenciesOptionName.value,
      value => {
        currUpdatingDependenciesOption.value = categoryOptionStore.getOption(
          value
        );
      }
    );
    const updateOptionDependencies = (e: Event) => {
      isAddingOptionDependency.value = false;

      if (!currUpdatingDependenciesOption.value) return;

      categoryOptionStore.addCategoryOption(
        currUpdatingDependenciesOption.value
      );
      /* 
      configStore.addCategoryOption(
        props.configId,
        currUpdatingDependenciesOption.value
      ); */
    };

    return {
      categorySetNames,
      possibleCategories,
      possibleCategoryNames,
      possibleOptions,
      possibleOptionNames,
      categoryOptions,
      categoryOptionNames,
      isAddingCategoryDependency,
      currUpdatingDependenciesCategoryName,
      currUpdatingDependenciesCategory,
      updateCategoryDependencies,
      isAddingOptionDependency,
      currUpdatingDependenciesOptionName,
      currUpdatingDependenciesOption,
      updateOptionDependencies,
    };
  },
});
