























import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import { RenderSettings, RenderTypes, Debug } from "@monowarestudios/js-util";
import { parseCSS, encodeCSS } from "../../../css";
import BModalWrapper from "@/components/BModalWrapper.vue";

type StringifiedRenderType = {
  renderType: string;
  CSS: string;
};

@Component({ components: { BModalWrapper } })
export default class EditRenderSettingsArray extends Vue {
  @Prop() private value!: RenderSettings[];
  @Prop() private type!: string;
  @Ref() modal!: BModalWrapper;
  private stringifiedRenderTypes: StringifiedRenderType[] = [];
  private renderTypes = RenderTypes;
  @Prop() debug!: Debug;

  private parseCSS() {
    // for each, split the string on new line and construct object split on
    return this.stringifiedRenderTypes.map((item: StringifiedRenderType) => ({
      renderType: item.renderType,
      css: parseCSS(item.CSS),
    }));
  }

  private encodeCSS() {
    // convert from map[string]map[string]string to map[string]string, making sure to add newones
    const makeBaked = (r: RenderSettings) => ({
      renderType: r.renderType,
      CSS: encodeCSS(r.css),
    });
    const getRenderTypes = (r: StringifiedRenderType) => r.renderType;
    let data = this.value.map(makeBaked);
    const inData = data.map(getRenderTypes);
    this.stringifiedRenderTypes = [
      ...data,
      ...this.renderTypes.option
        .filter((r: RenderSettings) => !inData.includes(r.renderType))
        .map(makeBaked),
    ];
  }

  public async show(id: number) {
    this.encodeCSS();
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  private cancel() {
    this.hide();
  }

  private async save() {
    this.$emit("input", this.type, this.value);
  }
}
