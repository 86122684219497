









import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import { BModal } from "bootstrap-vue";

@Component
export default class BModalWrapper extends Vue {
  @Prop() title!: string;
  @Prop() size!: string;
  @Prop() dontUseSave!: string;
  @Ref() modal!: BModal;

  public show() {
    this.modal.show();
  }

  public hide() {
    this.$emit("hide");
    this.modal.hide();
  }

  public save() {
    this.$emit("save");
    this.hide();
  }
}
