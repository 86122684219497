



























import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import BModalWrapper from './BModalWrapper.vue';
import { Debug, OrderDetailsConfig, Requests } from '@monowarestudios/js-util'

interface IMessage {
  internalName: string;
  message: string;
  link: string;
}

@Component({ components: { BModalWrapper } })
export default class EditCartView extends Vue {
  @Prop() debug!: Debug;
  @Prop() propClientID!: number;

  @Ref() modal!: BModalWrapper;

  status = ""

  orderDetailsConfig: OrderDetailsConfig = {
    id: -1,
    clientId: -1,
    itemList: {
      showAsTable: true,
      showAddonsAsSubTable: false,
      showDiscounts: false,
      showItemTotal: false,
      showPrice: false,
      showProduct: false,
      showQuantity: false,
      showSKU: false,
      showShipping: false,
      showSubtotal: false,
      showTotal: false,
    },
  }

  public async show(id: number) {
    try {
      const [res, success] = await Requests.get("orderDetailsConfig", id)
      if (success) {
        this.orderDetailsConfig = res as OrderDetailsConfig
      }
    } catch (e) {
      console.error(e);
    }

    this.modal.show()
  }
  public async hide(id: number) {
    this.modal.hide()
  }

  async onSave() {
    try {
      const [_data, success] = await Requests.update("orderDetailsConfig", this.orderDetailsConfig)

      if (success) {
        return this.status = " Saved"
      }

      return this.status = " Failed to Save"
    } catch (error) {
      console.error(error);
    }
  }


  async deploy() {
    const [_data, success] = await Requests.deploy("orderDetailsConfig", this.orderDetailsConfig.id)

    if (success) {
      return this.status = "Deployed"
    }

    return this.status = "Failed to deploy"
  }
}
