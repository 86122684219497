


































import { useCssSnippetStore } from "@/stores/CssSnippetStore";
import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";

import BModalWrapper from "../BModalWrapper.vue";
import { CssSnippet} from "@monowarestudios/js-util";

@Component({
  components: {
    BModalWrapper,
  },
})
export default class CssSnippetEditor extends Vue {
  @Prop() private show!: boolean;
  @Prop() private usedSnippets!: CssSnippet[];
  @Prop() private clientId!: number;
  @Prop() private isAddable!: boolean;
  @Prop() private isEditable!: boolean;
  @Prop() private canEditUsed!: boolean;
  @Prop() private canDelete!: boolean;

  @Ref() private modalRef!: BModalWrapper;

  private snippetStore = useCssSnippetStore();

  private get availableCssSnippets() {
    if (!this.snippetStore.loading) {
      return [
        ...this.snippetStore.getDefaultCssSnippets,
        ...this.snippetStore.getAllClientCssSnippets(this.clientId),
      ];
    }

    return [];
  }

  private isEditing = false;
  private isAdding = false;
  private currSnippetIndex = 0;
  private newSnippets: CssSnippet[] = [];
  private editedSnippets: CssSnippet[] = [];
  private currSnippet: CssSnippet | null = null;
  private deletedSnippets: CssSnippet[] = [];

  private newId = -1;
  private newSnippet: CssSnippet = {
    id: this.newId,
    snippet: "",
    clientId: this.clientId,
    templateName: "",
  };

  private changeSnippetIndex(e: any) {
    if (e.moved) {
      if (e.moved.oldIndex === this.currSnippetIndex) {
        this.currSnippetIndex = e.moved.newIndex;
      }
    }
  }

  private editSnippet() {
    if (!this.currSnippet) return;

    this.editedSnippets.push(this.currSnippet);
  }
  private addSnippet() {
    Array.from(this.snippetStore.snippets.keys()).forEach(id => {
      if (this.newId === id) {
        this.newId = this.newId - 1;
      }
    });

    this.newSnippet.id = this.newId;

    this.newSnippets.push({ ...this.newSnippet, clientId: this.clientId });
    this.snippetStore.addSnippet(this.newSnippet as CssSnippet);
  }

  private onSave() {
    this.$emit(
      "save",
      this.newSnippets,
      this.editedSnippets,
      this.usedSnippets,
      this.deletedSnippets
    );
  }

  @Watch("clientId")
  private watchClientId(val: number) {
    if (val < 1) return;
    this.snippetStore.loadSnippets(val);
  }
  @Watch("show")
  private watchShow(val: boolean) {
    val ? this.modalRef!.show() : null;
  }
}
