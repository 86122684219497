import {
  Config,
  CategoryOverride,
  Category,
  CategorySet,
  JSONSet,
  SetCategory,
  SetContainer,
} from "@monowarestudios/js-util";
import { defineStore } from "pinia";
import { useCategoryOptionStore } from "./CategoryOptionStore";
import { useCategorySetStore } from "./CategorySetStore";
import { useSetCategoryStore } from "./SetCategoryStore";
import { useSetContainerStore } from "./SetContainerStore";
interface StoreState {
  configs: Map<number, Config>;
  currentConfig: number;
}

export const useConfigStore = defineStore({
  id: "ConfigStore",
  state: (): StoreState => ({
    configs: new Map(),
    currentConfig: 0,
  }),
  getters: {
    getConfig(state) {
      return (configId: number) => {
        return state.configs.get(configId);
      };
    },
    allConfigs: state => Array.from(state.configs.values()),
    getContainers() {
      return (configId: number) => {
        const setContainerStore = useSetContainerStore();

        const config = this.getConfig(configId);

        if (!config || !config.containers) return [];

        return setContainerStore.getContainers(
          config.containers.map((c: SetContainer) => c.internalName)
        );
      };
    },
    getCategorySets() {
      return (configId: number) => {
        const categorySetStore = useCategorySetStore();

        const config = this.getConfig(configId);

        if (!config || !config.sets) return [];

        return categorySetStore.getSets(
          config.sets.map((set: CategorySet) => set.internalName)
        );
      };
    },
    getSetCategories() {
      return (configId: number) => {
        const sets = this.getCategorySets(configId);

        const setCategoryStore = useSetCategoryStore();

        return sets
          .flatMap((set: CategorySet | undefined) => {
            if (!set) return;

            return setCategoryStore.getSetCategories(set.internalName);
          })
          .filter((cat: SetCategory | undefined) => cat !== undefined);
      };
    },
    getUnusedCategories() {
      return (configId: number) => {
        const config = this.getConfig(configId);

        if (!config) return [];

        const catInternalNames = Array.from(
          new Set([
            ...config.categories.map((cat: Category) => cat.internalName),
            ...config.categoryOverrides.map(
              (co: CategoryOverride) => co.internalName
            ),
          ])
        );

        const currSetCategoriesInternalNames = this.getSetCategories(
          configId
        ).map((csc: SetCategory | undefined) => csc?.categoryInternalName);

        const setCategoryStore = useSetCategoryStore();

        return setCategoryStore.getCategoriesFromInternalNames(
          catInternalNames.filter(
            cin => !currSetCategoriesInternalNames.includes(cin)
          )
        );
      };
    },
    getPossibleCategories() {
      return (configId: number) => {
        return [
          ...this.getSetCategories(configId),
          ...this.getUnusedCategories(configId),
        ];
      };
    },
  },
  actions: {
    addConfig(config: Config) {
      this.configs.set(config.id, config);

      const setContainerStore = useSetContainerStore();
      const categorySetStore = useCategorySetStore();
      const setCategoryStore = useSetCategoryStore();
      const categoryOptionStore = useCategoryOptionStore();

      if (!config) return;

      if (config.containers.length !== 0) {
        setContainerStore.addContainers(config.containers);
      }

      if (config.sets) {
        categorySetStore.addSets(config.sets);
      }

      setCategoryStore.addSetCategories(
        config.categoryOverrides.map(co => {
          categoryOptionStore.addCategoryOptions(
            co.category.options.map(o => {
              return {
                categoryId: co.internalName,
                optionInternalName: o.internalName,
                option: o,
                isVisible: false,
                isEnabled: false,
                isSelected: false,
                needThisOptionVisibleToShow: {
                  categorySets: [],
                  setCategories: [],
                  categoryOptions: [],
                },
                needThisOptionNotVisibleToShow: {
                  categorySets: [],
                  setCategories: [],
                  categoryOptions: [],
                },
                needThisOptionEnabledToShow: {
                  categorySets: [],
                  setCategories: [],
                  categoryOptions: [],
                },
                needThisOptionNotEnabledToShow: {
                  categorySets: [],
                  setCategories: [],
                  categoryOptions: [],
                },
                needThisOptionSelectedToShow: {
                  categorySets: [],
                  setCategories: [],
                  categoryOptions: [],
                },
                needThisOptionNotSelectedToShow: {
                  categorySets: [],
                  setCategories: [],
                  categoryOptions: [],
                },
              };
            })
          );

          return {
            categoryInternalName: co.internalName,
            category: co.category,
            options: new JSONSet<string>(
              co.category.options.map(o => o.internalName)
            ),
            isVisible: false,
            needThisCategoryVisibleToShow: {
              categorySets: [],
              setCategories: [],
              categoryOptions: [],
            },
            needThisCategoryNotVisibleToShow: {
              categorySets: [],
              setCategories: [],
              categoryOptions: [],
            },
          };
        })
      );
    },
    addConfigs(configs: Config[]) {
      configs.forEach(config => {
        this.addConfig(config);
      });
    },
    addContainer(configId: number, container: SetContainer) {
      const config = this.getConfig(configId);

      if (!config) return;

      if (!config.containers) config.containers = [];

      config.containers.push(container);
    },
    addCategorySet(configId: number, categorySet: CategorySet) {
      const config = this.getConfig(configId);

      if (!config) return;

      if (!config.sets) config.sets = [];

      config.sets.push(categorySet);
    },
    removeContainer(configId: number, containerInternalName: string) {
      const config = this.getConfig(configId);

      if (!config) return;

      const containerPos = config.containers.findIndex(
        (c: SetContainer) => c.internalName === containerInternalName
      );

      if (containerPos < 0) {
        return;
      }

      if (config.containers.length == 1) {
        config.containers = [];
      }

      config.containers = [
        ...config.containers.slice(0, containerPos),
        ...config.containers.slice(containerPos + 1),
      ];
    },
  },
});

export default useConfigStore;
