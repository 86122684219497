




















import { Component, Watch, Prop, Vue } from "vue-property-decorator";

import { StandaloneNavSetting, NavState, ProgressBarModes } from "@monowarestudios/js-util";

@Component
export default class StandaloneNavEditor extends Vue {
  @Prop() value!: StandaloneNavSetting;
  @Prop() navs!: string[];
  private selected: StandaloneNavSetting = {
    progressBarMode: ProgressBarModes.FRACTION,
    nav: "",
    internalName: "",
    includePriceBreakdown: false,
    showCurrentPosition: false
  };

  @Watch("value")
  private onUsageChange() {
    this.selected = JSON.parse(JSON.stringify(this.value));
  }

  private mounted() {
    this.onUsageChange();
  }

  private save() {
    this.$emit("input", this.selected);
  }
}
