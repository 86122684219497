







import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import { Config, Debug } from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";
import VueMultiselect from "vue-multiselect";

@Component({ components: { BModalWrapper, VueMultiselect } })
export default class ConfigDuplicate extends Vue {
  @Prop() debug!: Debug;
  @Prop() existingConfigs!: Config[];
  @Ref() modal!: BModalWrapper;
  private selectedConfig: Config | null = null;
  private sorted: Config[] = [];
  public show() {
    this.sorted = JSON.parse(JSON.stringify(this.existingConfigs));
    this.sorted.sort();
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  public save() {
    this.$emit("configDuplicate", this.selectedConfig!.id);
    this.selectedConfig = null;
    this.hide();
  }
}
