






























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { CameraMovementSetting, Configurator } from "@monowarestudios/js-util";
@Component
export default class CameraSettingsView extends Vue {
  @Prop() value!: CameraMovementSetting;
  @Prop() configurator!: Configurator;
  private cameraSettings: CameraMovementSetting = CameraMovementSetting.fromData(
    {}
  );

  private mounted() {
    this.setup();
  }

  @Watch("value", { deep: true })
  private setup() {
    this.cameraSettings = this.value;
    if (Number(this.cameraSettings.time) == 0) this.cameraSettings.time = 1;
  }

  private update() {
    this.$emit("input", this.cameraSettings);
  }

  private async getCameraPosition() {
    const camera = await this.configurator.getCamera();
    this.cameraSettings.position = camera.position;
    this.cameraSettings.target = camera.target;
  }
}
