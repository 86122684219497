


















































import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import { Standalone, Debug, Config } from "@monowarestudios/js-util";
import BModalWrapper from "@/components/BModalWrapper.vue";
import StandaloneSettingEditor from "./StandaloneSettingEditor.vue";
import StandaloneNavSettingEditor from "./StandaloneNavSettingEditor.vue";
import StandaloneModalSettingEditor from "./StandaloneModalSettingEditor.vue";
import StandaloneAddToCartSettingEditor from "./StandaloneAddToCartSettingEditor.vue";
import StandaloneFormEditor from "./StandaloneFormEditor.vue";
import StandaloneFinanceSettingEditor from "./StandaloneFinanceSettingEditor.vue";
import StandaloneCartSummarySettingEditor from "./StandaloneCartSummarySettingEditor.vue";
import StandaloneCurrencySelectorEditor from "./StandaloneCurrencySelectorEditor.vue";
import StandalonePrintSettingEditor from "./StandalonePrintSettingEditor.vue";

@Component({ components: { StandaloneCurrencySelectorEditor, BModalWrapper, StandaloneFormEditor, StandaloneSettingEditor, StandaloneNavSettingEditor, StandaloneModalSettingEditor, StandaloneAddToCartSettingEditor, StandaloneFinanceSettingEditor, StandaloneCartSummarySettingEditor, StandalonePrintSettingEditor } })
export default class ConfigStandaloneEditor extends Vue {
  @Prop() debug!: Debug;
  @Prop() value!: Standalone;
  @Prop() navs!: string[];
  @Prop() config!: Config
  @Ref() modal!: BModalWrapper;
  model: Standalone = { currencySelector:[], text: [], print: [], image: [], progressBar: [], standaloneNav: [], modal: [], addToCartButton: [], form: [], cartSummary: [], financingComponent: [] };

  public show() {
    this.model = JSON.parse(JSON.stringify(this.value || { text: [], print: [], image: [], progressBar: [], standaloneNav: [], modal: [], addToCartButton: [], form: [], cartSummary: [], financingComponent: [] }));

    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

  public save() {
    this.$emit("input", this.model);
    this.hide();
  }
}
