






















import { ReferenceTypes, StandaloneReference } from '@monowarestudios/js-util';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect'


@Component({ components: { Multiselect } })
export default class StandaloneModalEditor extends Vue {
  @Prop() value!: StandaloneReference
  @Prop() options!: string[]

  get internalName() {
    return this.value.internalName
  }
  set internalName(v:string) {
    this.$emit("input", {...this.value, internalName: v})
  }
  get buttonText() {
    return this.value.buttonText
  }
  set buttonText(v:string) {
    this.$emit("input", {...this.value, buttonText: v})
  }
  get targetInternalName() {
    return this.value.targetInternalName
  }
  set targetInternalName(v:string) {
    this.$emit("input", {...this.value, targetInternalName: v})
  }
  get referenceType() {
    return this.value.type
  }
  set referenceType(v:string) {
    this.$emit("input", {...this.value, type: v})
  }

  get referenceTypes() {
    return Object.values(ReferenceTypes)
  }

  selectReferenceType(referenceType: ReferenceTypes) {
    this.referenceType = referenceType
  }
}
