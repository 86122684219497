



































































































































import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";
import {
  Requests,
  Config,
  Category,
  VariantSet,
  Variant,
  Product,
  Debug,
  ClientCSS,
  EmptyVariantSet,
  Configurator,
  CategoryOverride,
  CssSnippet,
  BakedConfig,
} from "@monowarestudios/js-util";
import ConfigCssView from "./config_details_views/RenderSettingsView.vue";
import ConfigDefaults from "./config_details_views/ConfigDefaults.vue";
import ConfigConfirmations from "./ConfigConfirmations.vue";
import CategoryDetailsView from "./config_details_views/category/CategoryDetailsView.vue";
import ConfigVariantEditor from "./product_details_views/ConfigVariantEditor.vue";
import EditRenderSettingsArray from "./config_details_views/EditRenderSettingsArray.vue";
import ConfigDuplicate from "./ConfigDuplicate.vue";
import ConfigCategorySetEditor from "./config_details_views/category-containers/ConfigCategorySetEditor.vue";
import SetContainerEditor from "./config_details_views/category-containers/SetContainerEditor.vue";
import useConfigStore from "@/stores/ConfigStore";
import CssSnippetEditor from "./CssSnippetEditor.vue";
import ConfigSequenceCategory from "./ConfigSequenceCategory.vue";
import ConfigFeatureFlags from "./ConfigFeatureFlags.vue";
import ConfigStandaloneEditor from "./ConfigStandaloneEditor.vue";
import ConfigValidationEditor from "./ConfigValidationEditor.vue";
import HubspotMappings from "./HubspotMappings.vue";

@Component({
  components: {
    ConfigDuplicate,
    ConfigCssView,
    CategoryDetailsView,
    ConfigVariantEditor,
    ConfigDefaults,
    ConfigConfirmations,
    EditRenderSettingsArray,
    ConfigCategorySetEditor,
    SetContainerEditor,
    CssSnippetEditor,
    ConfigSequenceCategory,
    ConfigFeatureFlags,
    ConfigStandaloneEditor,
    ConfigValidationEditor,
    HubspotMappings,
  },
})
export default class ConfigDetailsView extends Vue {
  @Prop() debug!: Debug;
  @Prop() configurator!: Configurator;
  @Prop() product!: Product;
  @Prop() materialList!: Record<string, any>;
  @Prop() geometryList!: Record<string, any>;
  @Prop() geometryTree!: Record<string, any>;
  @Prop() annotations!: any[];
  @Prop() currentDeployedConfig!: number;
  @Prop() baked!: BakedConfig;

  @Ref() variantEditor!: ConfigVariantEditor;
  @Ref() configCss!: ConfigCssView;
  @Ref() configDefaults!: ConfigCssView;
  @Ref() configDuplicate!: ConfigDuplicate;
  @Ref() configConfirmations!: ConfigConfirmations;
  @Ref() configCategorySetEditor!: typeof ConfigCategorySetEditor;
  @Ref() setContainerEditor!: typeof SetContainerEditor;
  @Ref() cssSnippetEditor!: typeof CssSnippetEditor;
  @Ref() productInfoCss!: ConfigCssView;
  @Ref() hackySequenceCategoryEditor!: typeof ConfigSequenceCategory;
  @Ref() featureFlagsEditor!: typeof ConfigFeatureFlags;
  @Ref() configStandaloneEditor!: typeof ConfigStandaloneEditor;
  @Ref() configValidationEditor!: typeof ConfigValidationEditor;
  @Ref() hubspotMappings!: typeof HubspotMappings;

  private configs: Config[] = [];
  private productConfigs: Config[] = [];
  private productConfigsList: any[] = [];
  private otherProducts: Product[] = [];
  private config: Config = new Config(); // our local state
  private selectedConfigID = 0;
  private status = "";
  private reordering = false;
  private showCategoryDetails = false;
  private shouldShowCssSnippetEditor = false;
  private shouldShowMobileContainerEditor = false;
  private shouldShowCategorySetEditor = false;
  private shouldShowContainerEditor = false;
  private categoryTemplates: Category[] = [];
  private selectedExistingCategory: Category = new Category();
  private existingClientCSS: ClientCSS[] = [];
  private override: CategoryOverride = new CategoryOverride();
  private categoryNameFilter = "";

  private get filteredOverrides() {
    return this.config.categoryOverrides?.filter(o =>
      this.categoryNameFilter
        ? o.internalName
          .toLowerCase()
          .includes(this.categoryNameFilter.toLowerCase()) ||
        o.displayName
          .toLowerCase()
          .includes(this.categoryNameFilter.toLowerCase())
        : true
    );
  }
  private test = [
    { name: "test1", id: 1, options: [] },
    { name: "test2", id: 2, options: [] },
    { name: "test3", id: 3, options: [] },
  ];

  @Watch("product.id")
  private productChanged() {
    this.loadCategoryTemplates();
    this.loadExistingConfigs();
    this.loadProductConfigs();
    this.loadClientCSS();
    this.loadOtherProducts();
  }
  private get mergedCategories() {
    return this.config.categoryOverrides.map((c: CategoryOverride) => {
      const category = c.categoryID && this.categoryTemplates?.length > 0
        ? this.categoryTemplates.find(cat => cat.id == c.categoryID)!
        : c.category;
      return {
        ...category,
        displayName: c.displayName,
        internalName: c.internalName,
      };
    })
  }

  private get variants() {
    return this.config.variantMap.flatMap((v: VariantSet) => v.variants.map((va: Variant) => ({ value: va.id, text: va.title })));
  }
  private get products() {
    return this.config.variantMap.flatMap((v: VariantSet) => ({ value: v.productID, text: v.productName }));
  }
  private get containers() {
    return this.config.containers
  }

  private get standaloneImages() {
    return this.config.standalone.image
  }
  private get possibleCartSummarys() {
    return this.config.standalone.cartSummary
  }
  private get possibleCurrencySelectors() {
    return this.config.standalone.currencySelector
  }
  private get standaloneTexts() {
    return this.config.standalone.text
  }
  private get forms() {
    return this.config.standalone.form
  }
  private get addToCartButtons() {
    return this.config.standalone.addToCartButton
  }
  private get financeComponents() {
    return this.config.standalone.financingComponent
  }
  private get printComponents() {
    return this.config.standalone.print
  }
  private get modals() {
    return this.config.standalone.modal
  }
  private get sets() {
    return this.config.sets
  }

  private get options() {
    return this.mergedCategories
      .map(c => c.options.map(o => `${c.internalName}::${o.internalName}`))
      .flat();
  }

  private get navs() {
    return this.config?.containers?.filter((c: any) => c.isNav).map(c => c.internalName);
  }

  private async loadOtherProducts() {
    const [data, success] = await Requests.listScoped(
      "product",
      parseInt(this.$route.params.client)
    );
    if (success) {
      this.otherProducts = data as Product[];
    } else {
      this.otherProducts = [];
      this.status = data as string;
    }
  }

  private async loadExistingConfigs() {
    const [data, success] = await Requests.listScoped(
      "config",
      parseInt(this.$route.params.client)
    );
    if (success) {
      this.configs = data as Config[];
      const configStore = useConfigStore();
      configStore.addConfigs(this.configs);
    } else {
      this.configs = [];
      this.status = data as string;
    }
  }

  private get availableStandaloneTexts() {
    return this.config.standalone.text;
  }
  private get availableStandaloneImages() {
    return this.config.standalone.image;
  }

  private async deployConfig() {
    const [data, success] = await Requests.deploy("config", this.config.id);
    if (success) {
      this.$emit("deployed", this.config.id);
    } else this.status = data as string;
  }
  private async softDeployConfig() {
    const [data, success] = await Requests.softDeploy(this.config.id);
    if (success) {
      this.$emit("deployed", this.config.id);
    } else this.status = data as string;
  }
  private async deployStage() {
    const [data, success] = await Requests.deployStage(this.config.id);
    if (success) {
      this.$emit("deployed", this.config.id);
    } else this.status = data as string;
  }

  private async loadProductConfigs() {
    const [data, success] = await Requests.listScopedByProduct(
      "config",
      this.product.id
    );
    if (success) {
      this.productConfigs = data as Config[];
      this.productConfigs = data as Config[];
      this.productConfigsList = (data as Config[]).map(c => ({
        name: c.name,
        id: c.id,
      }));
      this.selectedConfigID = this.productConfigs[0].id;
    } else {
      this.productConfigs = [];
      this.status = data as string;
    }
  }

  private async loadClientCSS() {
    this.status = "";
    try {
      const [data, success] = await Requests.listScoped(
        "css",
        parseInt(this.$route.params.client)
      );
      if (success) {
        this.existingClientCSS = data as ClientCSS[];
      } else {
        this.status = ("Failed to load data!\n" + data) as string;
        this.existingClientCSS = [];
      }
    } catch (err) {
      this.status = "Failed to load data!\n" + err;
      this.existingClientCSS = [];
    }
  }

  private get canDeploy() {
    return this.config.id != 0;
  }

  private getOverrideDisplayName(internalName: string) {
    const cat = this.config.categoryOverrides.filter(
      c => c.internalName == internalName
    )[0] || { displayName: "shits broken" };
    return cat.displayName;
  }

  private async loadCategoryTemplates() {
    try {
      const [data, success] = await Requests.listScoped(
        "category",
        parseInt(this.$route.params.client)
      );
      if (success) {
        this.categoryTemplates = data as any;
      } else {
        this.status = data as string;
      }
    } catch (err) {
      this.status = err as string;
    }
  }

  @Watch("selectedConfigID")
  private async onSelectedConfigChange(id: number) {
    if (id != 0) {
      const [data, success] = await Requests.get("config", id);
      if (success) {
        // this is when we load initial config state - all modifications and accesses come from ConfigDetailsView.config
        this.config = data as Config;
        this.$emit("reload", this.config);
      } else {
        this.config = {
          ...new Config(),
          clientID: parseInt(this.$route.params.client),
        };
        this.status = data as string;
      }
    } else {
      // this should never be null
      this.config = this.productConfigs.find((c: Config) => c.id == 0)!;
      this.$forceUpdate();
    }
  }

  private async createFromTemplate() {
    if (this.selectedExistingCategory.id) {
      const [data, success] = await Requests.get(
        "category",
        this.selectedExistingCategory.id!
      );
      if (success) {
        this.override = {
          ...new CategoryOverride(),
          internalName: "",
          displayName: "",
          categoryID: 0,
          category: data as Category,
        };
        this.showCategoryDetails = true;
      } else {
        this.status = ("Failed to load data!\n" + data) as string;
      }
    }
  }

  // these two have their response event handled by categoryUpdated
  private addCategory() {
    this.override = {
      ...new CategoryOverride(),
      displayName: "",
      internalName: "",
      categoryID: 0,
      category: new Category(),
    };
    this.showCategoryDetails = true;
  }

  private editCategory(internalName: string) {
    this.override = this.config.categoryOverrides.filter(
      (c: CategoryOverride) => c.internalName == internalName
    )[0];
    this.showCategoryDetails = true;
  }

  private categoryUpdated(c: CategoryOverride) {
    // TODO: probably need to rework this
    const index = this.config.categoryOverrides.findIndex(
      (co: CategoryOverride) => co.internalName == c.internalName
    );
    if (index == -1) {
      this.config.categoryOverrides.push(c);
    } else {
      this.config.categoryOverrides[index] = c;
    }
    this.$forceUpdate();
  }

  private deleteCategory(internalName: string) {
    this.config.categoryOverrides = this.config.categoryOverrides.filter(
      (c: CategoryOverride) => c.internalName != internalName
    );
  }

  private copyCategory(internalName: string) {
    this.override = JSON.parse(
      JSON.stringify({
        ...this.config.categoryOverrides.filter(
          (c: CategoryOverride) => c.internalName == internalName
        )[0],
        internalName: "",
      })
    );
    this.showCategoryDetails = true;
  }

  private hide() {
    this.$emit("hide");
  }

  private async regenMappings(skip: boolean) {
    if (skip) {
      // categories were regenerated so this needs to be redone anyways
      this.config.variantMap = [
        {
          ...EmptyVariantSet(),
          productID: this.product.productExternalID,
          variants: this.product.variants,
        },
      ];
    } else {
      const conf = await this.$bvModal.msgBoxConfirm(
        `WARNING: this will wipe out any changes you have made to variant mappings!`,
        { title: "Confirm update" }
      );
      if (conf) {
        this.config.variantMap = [
          {
            ...EmptyVariantSet(),
            productID: this.product.productExternalID,
            variants: this.product.variants,
          },
        ];
      }
    }
  }

  private newConfig() {
    if (!this.productConfigs.some((c: Config) => c.id == 0)) {
      this.productConfigs.push({
        ...new Config(),
        clientID: parseInt(this.$route.params.client),
        productID: this.product.id,
      });
    }
    this.selectedConfigID = 0;
  }

  private async duplicateTarget(id: number) {
    const [data, success] = await Requests.get("config", id);
    if (success) {
      const newConfig = {
        ...(data as Config),
        deployed: false,
        productID: this.product.id,
        id: 0,
      };
      this.productConfigs.push(newConfig);
      this.selectedConfigID = 0;
      this.onSelectedConfigChange(0);
    } else {
      this.status = ("Could not load target config: " + data) as string;
    }
  }
  private async duplicateCurrent() {
    const newConfig = {
      ...this.config,
      name: "",
      deployed: false,
      id: 0,
    };
    this.productConfigs.push(newConfig);
    this.selectedConfigID = 0;
    this.onSelectedConfigChange(0);
  }

  private async saveConfig() {
    if (this.config.name == "") {
      this.status =
        "Please name the config, otherwise the other configs will tease it.";
      return;
    }
    this.config.materialList = this.materialList;
    this.config.geometryList = this.geometryList
    const [data, success] = await (this.config!.id == 0
      ? Requests.create("config", this.config)
      : Requests.update("config", this.config));
    if (success) {
      this.status = "Config saved!";
      // trigger reload of config so that we ensure fresh and and juicy state
      // we want only the freshest and juiciest states here at monoware studios
      if (this.selectedConfigID == 0) this.config = data as Config;
      this.$emit("reload", this.config);
    } else {
      this.status = ("Could not save target config: " + data) as string;
    }
  }

  private async deleteConfig() {
    const [data, success] = await Requests.delete(
      "config",
      this.selectedConfigID
    );
    if (success) {
      const i = this.productConfigs.findIndex(
        (c: Config) => c.id == this.selectedConfigID
      );
      this.productConfigs.splice(i, 1);
      this.selectedConfigID = this.productConfigs.length - 1;
    } else {
      this.status = ("Could not delete target config: " + data) as string;
    }
  }
  private bakeCurrentConfig() {
    this.$emit("reload", this.config);
  }

  private async saveSnippets(
    newSnippets: CssSnippet[],
    editedSnippets: CssSnippet[],
    usedSnippets: CssSnippet[],
    deletedSnippets: CssSnippet[]
  ) {
    console.log(editedSnippets);

    const created = newSnippets.map(s => Requests.create("snippet", s));
    const updated = editedSnippets.map(s => Requests.update("snippet", s));
    const deleted = deletedSnippets.map(s => Requests.delete("snippet", s.id));

    await Promise.all([...created, ...updated, ...deleted]);

    this.config.cssSnippets = usedSnippets;
  }

  // show toggles
  private showConfigDuplicate() {
    this.configDuplicate.show();
  }
  private showConfigCss() {
    this.configCss.show();
  }
  private showConfigDefault() {
    this.configDefaults.show();
  }
  private showConfirmations() {
    this.configConfirmations.show();
  }
  private showVariantMapping() {
    if (this.config.variantMap.length == 0) {
      this.config.variantMap.push({
        ...EmptyVariantSet(),
        productID: this.product.productExternalID,
      });
    }
    this.variantEditor.show();
  }
  private showConfigCategorySetEditor() {
    this.shouldShowCategorySetEditor = true;
  }
  private showSetContainerEditor() {
    this.shouldShowContainerEditor = true;
  } /* 
  private showAddMobileContainer() {
    this.shouldShowMobileContainerEditor = true;
  } */
  private showCssSnippetEditor() {
    this.shouldShowCssSnippetEditor = true;
  }
  private showProductInfoCss() {
    this.productInfoCss.show();
  }
  private showHackySequenceCategory() {
    (this.hackySequenceCategoryEditor as any).show();
  }
  private showFeatureFlags() {
    (this.featureFlagsEditor as any).show();
  }
  private showConfigStandaloneEditor() {
    (this.configStandaloneEditor as any).show();
  }
  private showHubspotMappings() {
    (this.hubspotMappings as any).show();
  }
  private showConfigValidationEditor() {
    (this.configValidationEditor as any).show();
  }
}
